import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { currentUserAtom, authLoadedAtom } from './AuthAtoms';
import { fetchUserDetails } from '../api/auth';

const AuthInitializer = () => {
    const setCurrentUser = useSetAtom(currentUserAtom);
    const setAuthLoaded = useSetAtom(authLoadedAtom);

    useEffect(() => {
        const initializeAuth = async () => {
            const idToken = localStorage.getItem('idToken');
            if (idToken) {
                try {
                    const userDetails = await fetchUserDetails(idToken);
                    setCurrentUser(userDetails.user);
                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
            }
            setAuthLoaded(true);
        };

        initializeAuth();
    }, [setCurrentUser, setAuthLoaded]);

    return null; // No UI needed for this component
};

export default AuthInitializer;
