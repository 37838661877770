import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentUserAtom, authLoadedAtom } from '../auth/AuthAtoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faStore, faGear } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { handleLogout } from '../auth/AuthHelper';

function Navbar() {
    const [currentUser] = useAtom(currentUserAtom); // Access current user from Jotai atom
    const [authLoaded] = useAtom(authLoadedAtom); // Access authLoaded state from Jotai atom
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLogoutFunction = async () => {
        try {
            await handleLogout();
            window.location.href = '/login';
        } catch (error) {
            console.error('Logout failed: ', error);
        }
    };

    if (!authLoaded) {
        return null; // or a loading spinner
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false); // This will close the menu when called
    };

    return (
        <div className="navbar-container">
            <button className="hamburger" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} size="lg" />
            </button>

            <div className={`navbar ${menuOpen ? 'open' : ''}`}>
                {/* Regular Links */}
                <div className="regular-nav">
                    <span>
                        <Link to="/" onClick={closeMenu}>
                            Home
                        </Link>
                    </span>
                    <span>
                        <Link to="/faq" onClick={closeMenu}>
                            FAQ
                        </Link>
                    </span>
                    <span>
                        <Link to="/leaderboard" onClick={closeMenu}>
                            Leaderboard
                        </Link>
                    </span>
                    <span>
                        <Link to="/campaigns" onClick={closeMenu}>
                            Campaigns
                        </Link>
                    </span>

                    {currentUser ? (
                        <>
                            <span>
                                <Link to="/team/user" onClick={closeMenu}>
                                    Teams
                                </Link>
                            </span>
                            <span>
                                <Link to="/packs/user" onClick={closeMenu}>
                                    Packs
                                </Link>
                            </span>
                            <span>
                                <Link to="/cards/user" onClick={closeMenu}>
                                    Cards
                                </Link>
                            </span>
                            <span>
                                <Link to="/wallet" onClick={closeMenu}>
                                    Wallet
                                </Link>
                            </span>
                            <span>
                                <Link to="/games/user" onClick={closeMenu}>
                                    Games
                                </Link>
                            </span>
                            <span>
                                <Link to="/account" onClick={closeMenu}>
                                    {currentUser.username} <FontAwesomeIcon icon={faGear} size="lg" />
                                </Link>
                            </span>
                            <button
                                onClick={() => {
                                    handleLogoutFunction();
                                    closeMenu();
                                }}
                                className="fake_link navbar-link less-action"
                            >
                                LogOut
                            </button>
                        </>
                    ) : (
                        <button className="fake_link navbar-link less-action">
                            <Link to="/login" onClick={closeMenu}>
                                Login
                            </Link>
                        </button>
                    )}
                    <span>
                        <a href="https://www.instagram.com/uraniumbaseball/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="lg" />
                        </a>
                    </span>
                    <span>
                        <a href="https://discord.gg/HuAJc3bP" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faDiscord} size="lg" />
                        </a>
                    </span>
                    <span>
                        <a href="https://www.etsy.com/shop/ThirdDanArt" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faStore} size="lg" />
                        </a>
                    </span>
                </div>

                {/* Admin Links */}
                {currentUser && currentUser.isAdmin && (
                    <div className="admin-nav">
                        <span>
                            <Link to="/admin/series-management" onClick={closeMenu}>
                                Series
                            </Link>
                        </span>
                        <span>
                            <Link to="/admin/cards/bulk" onClick={closeMenu}>
                                Bulk Cards
                            </Link>
                        </span>
                        <span>
                            <Link to="/admin/cardCustom" onClick={closeMenu}>
                                Custom Card
                            </Link>
                        </span>
                        <span>
                            <Link to="/admin/roll-page" onClick={closeMenu}>
                                Roll Dice
                            </Link>
                        </span>
                        <span>
                            <Link to="/team/view-comp-teams" onClick={closeMenu}>
                                Comp Team
                            </Link>
                        </span>
                        <span>
                            <Link to="/rewards/" onClick={closeMenu}>
                                Rewards
                            </Link>
                        </span>
                        <span>
                            <Link to="/admin/users" onClick={closeMenu}>
                                Users
                            </Link>
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Navbar;
