import axios from './axios';

export const fetchCards = async () => {
    try {
        const response = await axios.get(`/card`);
        return response.data;
    } catch (error) {
        console.error('Error fetching cards:', error);
        return [];
    }
};

export const fetchCardsByUserID = async () => {
    try {
        const response = await axios.get(`/card/user`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user cards:', error);
        return { cards: [], totalCards: 0 };
    }
};

export const fetchDupliateCardsByUserID = async () => {
    try {
        const response = await axios.get(`/cardInventory/user/duplicate`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user cards:', error);
        return { cards: [], totalCards: 0 };
    }
};
export const sellDuplicateCards = async () => {
    try {
        const response = await axios.post(`/cardInventory/sell/duplicate`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user cards:', error);
        return { cards: [], totalCards: 0 };
    }
};

export const createBulkCards = async year => {
    try {
        const response = await axios.post('/card/bulk', { year });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchCardsByYear = async year => {
    try {
        const response = await axios.get(`/card/year/${year}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchCardDetails = async cardID => {
    try {
        const response = await axios.get(`/card/${cardID}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const recalculateCardRating = async seriesID => {
    const response = await axios.get(`/card/recalculate-rating/${seriesID}`);
    return response.data;
};

export const fetchCardInventory = async cardID => {
    try {
        const response = await axios.post(`/cardInventory`, { cardID });
        return response.data;
    } catch (error) {
        console.error('Error fetching user card inventory:', error);
        return [];
    }
};
export const fetchUserCardInventory = async userCardIDs => {
    try {
        const response = await axios.post(`/cardInventory/userCards`, { userCardIDs });
        return response.data;
    } catch (error) {
        console.error('Error fetching user card inventory:', error);
        return [];
    }
};

export const updateCardInventory = async (inventoryID, displayKey) => {
    try {
        const response = await axios.put(`/cardInventory`, { inventoryID, displayKey });
        return response.data;
    } catch (error) {
        console.error('Error fetching user card inventory:', error);
        return [];
    }
};

export const fetchLeaderboard = async () => {
    try {
        const response = await axios.get(`/cardInventory/leaderboard`);
        return response.data;
    } catch (error) {
        console.error('Error fetching leaderboard:', error);
        return [];
    }
};

export const fetchLeaderboardMonthly = async monthYear => {
    try {
        const response = await axios.get(`/cardInventory/leaderboard/monthly/${monthYear}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching leaderboard:', error);
        return [];
    }
};
