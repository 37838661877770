import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchLeaderboardMonthly } from '../../api/cards';
import { toast } from 'react-toastify';
import LeaderboardList from '../../components/LeaderboardList';
import { readableMonthYear } from '../../helpers/dateHelpers';
const LeaderboardMonthlyPage = () => {
    const [leaderboard, setLeaderboard] = useState({ batters: [], pitchers: [] });
    const [loading, setLoading] = useState(false);
    const { monthYear } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await fetchLeaderboardMonthly(monthYear);
                setLeaderboard(data);
            } catch (error) {
                toast.error('Error fetching leaderboard: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [monthYear]);
    if (loading) {
        return <p>Loading...</p>;
    }
    return <LeaderboardList leaderboard={leaderboard} title={`${readableMonthYear(monthYear)} Monthly`} />;
};

export default LeaderboardMonthlyPage;
