import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Use location for editing
import { fetchCardsByUserID } from '../../api/cards';
import { createUserTeamWithLineup, editUserTeamWithLineup } from '../../api/userTeams'; // Import both functions
import { toast } from 'react-toastify';

import {
    calculateScore,
    createInitialLineup,
    getCardOptions,
    lineupOrderOptions,
    positions,
    sortCardsByRating,
} from '../../helpers/cardHelpers';

const CreateTeam = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Get the location data to detect edit mode
    const [loading, setLoading] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [score, setScore] = useState(0);
    const [userCards, setUserCards] = useState([]);
    const [filteredCards, setFilteredCards] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState(new Set());
    const [lineup, setLineup] = useState(createInitialLineup());
    const [pageSize, setPageSize] = useState(500);

    const isEditMode = location.state?.team; // Check if we're editing based on the passed state
    const existingTeam = location.state?.team || null; // Get the existing team details if editing

    useEffect(() => {
        if (isEditMode) {
            setTeamName(existingTeam.name);

            const transformedLineup = createInitialLineup(); // Start with an empty structure

            existingTeam.UserTeamLineups.forEach(entry => {
                const { position, cardID, lineupOrder } = entry;

                // Handle positions that allow multiple entries (e.g., SP, RP, Bench)
                if (Array.isArray(transformedLineup[position])) {
                    // Find the first available spot in the array
                    const index = transformedLineup[position].findIndex(pos => !pos.cardID);
                    if (index !== -1) {
                        transformedLineup[position][index] = { cardID, lineupOrder: lineupOrder || '' };
                    }
                } else {
                    // Single-card positions (e.g., C, 1B, 2B)
                    transformedLineup[position] = { cardID, lineupOrder: lineupOrder || '' };
                }
            });

            setLineup(transformedLineup);
            setScore(existingTeam.score);
        }
    }, [isEditMode, existingTeam]);

    const fetchUserCards = useCallback(async () => {
        try {
            const data = await fetchCardsByUserID(1, pageSize);
            if (Array.isArray(data.cards)) {
                setUserCards(data.cards);
                setFilteredCards(data.cards);
            } else {
                console.error('Unexpected data format', data);
            }
        } catch (error) {
            console.error('Error fetching user cards:', error);
        }
    }, [pageSize]);

    useEffect(() => {
        fetchUserCards();
    }, [fetchUserCards]);

    useEffect(() => {
        setScore(calculateScore(lineup, userCards));
    }, [lineup, userCards]);

    const handleChange =
        (position, index = null) =>
        event => {
            const cardID = event.target.value;
            const card = userCards.find(({ Card }) => Card.cardID == cardID);
            const playerID = card?.Card?.playerID;

            if (Array.isArray(lineup[position])) {
                const newPositions = [...lineup[position]];

                const oldCardID = newPositions[index]?.cardID;
                if (oldCardID) {
                    const oldCard = userCards.find(({ Card }) => Card.cardID == oldCardID);
                    const oldPlayerID = oldCard?.Card?.playerID;
                    setSelectedPlayers(prev => {
                        const newSet = new Set(prev);
                        newSet.delete(oldPlayerID);
                        return newSet;
                    });
                }

                if (playerID) {
                    setSelectedPlayers(prev => {
                        const newSet = new Set(prev);
                        newSet.add(playerID);
                        return newSet;
                    });
                }

                newPositions[index] = { cardID, lineupOrder: newPositions[index]?.lineupOrder || '' };
                setLineup(prevLineup => ({ ...prevLineup, [position]: newPositions }));
            } else {
                const oldCardID = lineup[position]?.cardID;
                if (oldCardID) {
                    const oldCard = userCards.find(({ Card }) => Card.cardID == oldCardID);
                    const oldPlayerID = oldCard?.Card?.playerID;
                    setSelectedPlayers(prev => {
                        const newSet = new Set(prev);
                        newSet.delete(oldPlayerID);
                        return newSet;
                    });
                }

                if (playerID) {
                    setSelectedPlayers(prev => {
                        const newSet = new Set(prev);
                        newSet.add(playerID);
                        return newSet;
                    });
                }

                setLineup(prevLineup => ({
                    ...prevLineup,
                    [position]: { cardID, lineupOrder: lineup[position]?.lineupOrder || '' },
                }));
            }
        };

    const handleLineupOrderChange =
        (position, index = null) =>
        event => {
            const newOrder = event.target.value;
            if (Array.isArray(lineup[position])) {
                const newPositions = [...lineup[position]];
                newPositions[index] = { ...newPositions[index], lineupOrder: newOrder };
                setLineup(prevLineup => ({ ...prevLineup, [position]: newPositions }));
            } else {
                setLineup(prevLineup => ({
                    ...prevLineup,
                    [position]: { ...lineup[position], lineupOrder: newOrder },
                }));
            }
        };

    const handlePageSizeChange = event => {
        setPageSize(parseInt(event.target.value));
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        try {
            //console.log('handleSubmit');
            const allowedFields = new Set(['C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'DH', 'SP', 'RP', 'Bench']);
            const cardIDs = new Set([]);
            const teamInfo = {
                score,
                name: teamName,
            };

            const lineupInfo = {};
            Object.entries(lineup).forEach(([position, value]) => {
                if (allowedFields.has(position)) {
                    if (Array.isArray(value)) {
                        lineupInfo[position] = value.map(({ cardID, lineupOrder }) => {
                            if (cardIDs.has(cardID)) {
                                throw Error('Only one copy of each card is allowed in your lineup');
                            }
                            cardIDs.add(cardID);
                            return {
                                cardID: cardID,
                                lineupOrder: Number.isFinite(parseInt(lineupOrder, 10))
                                    ? parseInt(lineupOrder, 10)
                                    : null,
                            };
                        });
                    } else if (value.cardID) {
                        if (cardIDs.has(value.cardID)) {
                            throw Error('Only one copy of each card is allowed in your lineup');
                        }
                        cardIDs.add(value.cardID);
                        lineupInfo[position] = {
                            cardID: value.cardID,
                            lineupOrder: Number.isFinite(parseInt(value.lineupOrder, 10))
                                ? parseInt(value.lineupOrder, 10)
                                : null,
                        };
                    }
                }
            });

            const teamPayload = { ...teamInfo, lineup: lineupInfo };
            if (existingTeam && existingTeam.userTeamID) {
                teamPayload.userTeamID = existingTeam.userTeamID;
            }
            // Submit to either create or edit endpoint based on mode
            if (isEditMode) {
                await editUserTeamWithLineup(teamPayload);
            } else {
                await createUserTeamWithLineup(teamPayload);
            }

            navigate('/team/user', {
                state: { message: `Team ${teamName} successfully ${isEditMode ? 'updated' : 'created'}!` },
            });
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(`Error: ${error.response.data.error}`);
            } else {
                toast.error(
                    `Error ${isEditMode ? 'updating' : 'creating'} user team and lineup: ${error.message || error}`
                );
            }
        } finally {
            setLoading(false);
        }
    };
    const handleFillBestLineup = () => {
        const newLineup = { ...lineup };
        const newSelectedPlayers = new Set();
        const sortedUserCards = sortCardsByRating(userCards);

        const addBestCard = (position, index = null) => {
            let availableCards;
            if (['LF', 'CF', 'RF'].includes(position)) {
                availableCards = sortedUserCards.filter(
                    ({ Card }) => Card.positions.includes('OF') && !newSelectedPlayers.has(Card.playerID)
                );
            } else if (position === 'DH' || position === 'Bench') {
                availableCards = sortedUserCards.filter(
                    ({ Card }) =>
                        !Card.positions.includes('SP') &&
                        !Card.positions.includes('RP') &&
                        !newSelectedPlayers.has(Card.playerID)
                );
            } else {
                availableCards = sortedUserCards.filter(
                    ({ Card }) => Card.positions.includes(position) && !newSelectedPlayers.has(Card.playerID)
                );
            }

            if (availableCards.length > 0) {
                const bestCard = availableCards[0];
                if (['LF', 'CF', 'RF'].includes(position)) {
                    if (!newLineup.LF.cardID) {
                        newLineup.LF = { cardID: bestCard.Card.cardID, lineupOrder: '' };
                    } else if (!newLineup.CF.cardID) {
                        newLineup.CF = { cardID: bestCard.Card.cardID, lineupOrder: '' };
                    } else {
                        newLineup.RF = { cardID: bestCard.Card.cardID, lineupOrder: '' };
                    }
                } else if (Array.isArray(newLineup[position])) {
                    newLineup[position][index] = { cardID: bestCard.Card.cardID, lineupOrder: '' };
                } else {
                    newLineup[position] = { cardID: bestCard.Card.cardID, lineupOrder: '' };
                }
                newSelectedPlayers.add(bestCard.Card.playerID);
                return true; // Indicate that a card was successfully added
            }
            return false; // Indicate that no card was added
        };

        // Clear existing lineup first
        Object.keys(newLineup).forEach(position => {
            if (Array.isArray(newLineup[position])) {
                newLineup[position] = newLineup[position].map(() => ({ cardID: '', lineupOrder: '' }));
            } else {
                newLineup[position] = { cardID: '', lineupOrder: '' };
            }
        });

        // Fill lineup positions
        positions.forEach(({ label, count }) => {
            for (let i = 0; i < (count || 1); i++) {
                addBestCard(label, i);
            }
        });

        // Set lineup order for batting positions
        const battingPositions = ['CF', 'RF', '3B', '1B', 'DH', 'LF', 'SS', '2B', 'C'];
        let orderCounter = 1;
        battingPositions.forEach(pos => {
            if (newLineup[pos]?.cardID) {
                newLineup[pos].lineupOrder = orderCounter.toString();
                orderCounter++;
            }
        });

        setLineup(newLineup);
        setSelectedPlayers(newSelectedPlayers);
        setScore(calculateScore(newLineup, userCards));
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Team Name:</label>
                <input type="text" value={teamName} onChange={e => setTeamName(e.target.value)} required />
            </div>
            <div>
                <label>Score:</label>
                <input type="number" value={score} readOnly />
            </div>

            <div>
                <label>Show Top Cards:</label>
                <select value={pageSize} onChange={handlePageSizeChange}>
                    <option value={500}>Top 500</option>
                    <option value={1000}>Top 1000</option>
                    <option value={2000}>Top 2000</option>
                    <option value={3000}>Top 3000</option>
                    <option value={5000}>Top 5000</option>
                </select>
            </div>

            <button type="button" onClick={handleFillBestLineup}>
                Fill Best Lineup
            </button>

            <h3>Lineup</h3>
            {(positions || []).map(({ label, type, count }) =>
                type === 'single' ? (
                    <div key={label}>
                        <label>{label}:</label>
                        <select value={lineup[label]?.cardID || ''} onChange={handleChange(label)} required>
                            <option value="">Select a card</option>
                            {getCardOptions(label, null, filteredCards, lineup, selectedPlayers || []).map(option => (
                                <option key={option.key} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {['C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'DH'].includes(label) && (
                            <select value={lineup[label]?.lineupOrder || ''} onChange={handleLineupOrderChange(label)}>
                                <option value="">Select Batting Order</option>

                                {(lineupOrderOptions || []).map(order => (
                                    <option key={order} value={order}>
                                        {order}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                ) : (
                    (Array.from({ length: count }) || []).map((_, index) => (
                        <div key={`${label}${index + 1}`}>
                            <label>
                                {label} {index + 1}:
                            </label>

                            <select
                                value={(lineup[label] && lineup[label][index]?.cardID) || ''}
                                onChange={handleChange(label, index)}
                                required
                            >
                                <option value="">Select a card</option>

                                {(getCardOptions(label, index, filteredCards, lineup, selectedPlayers) || []).map(
                                    option => (
                                        <option key={option.key} value={option.value}>
                                            {option.label}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    ))
                )
            )}
            <button type="submit" disabled={loading}>
                {loading
                    ? isEditMode
                        ? 'Updating Team...'
                        : 'Creating Team...'
                    : isEditMode
                    ? 'Update Team'
                    : 'Create Team and Lineup'}
            </button>
        </form>
    );
};

export default CreateTeam;
