// src/pages/ViewTeams.js
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchUserTeams } from '../../api/userTeams';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';
import { toast } from 'react-toastify';
import TeamList from '../../components/TeamList';

const ViewTeams = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const [teams, setTeams] = useState([]);
    const location = useLocation();

    const fetchUserTeamsData = useCallback(async () => {
        if (!currentUser) return;

        try {
            const teams = await fetchUserTeams();
            setTeams(teams);
        } catch (error) {
            console.error('Error fetching user teams:', error);
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            fetchUserTeamsData();
        }

        if (location.state?.message) {
            //console.log('location.state.message');
            toast.success(location.state.message);
        }
    }, [currentUser, fetchUserTeamsData, location.state]);

    return (
        <div className="user-teams-page">
            <h2>User Teams</h2>
            <div>
                <Link to="/team/create">Create a New User Team</Link>
                <br />
                <br />
            </div>
            <TeamList teams={teams} />
        </div>
    );
};

export default ViewTeams;
