import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';
import CardTable from '../../components/CardTable';
import { fetchCardsByUserID } from '../../api/cards';

const UserCardsPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'rating', direction: 'descending' });
    const [filters, setFilters] = useState({ year: '', rating: '', team: '' });

    useEffect(() => {
        const fetchUserCards = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await fetchCardsByUserID();
                const userCards = data.cards.map(cardInventory => ({
                    ...cardInventory.Card,
                    amount: cardInventory.amount,
                }));
                setCards(userCards);
            } catch (error) {
                setError('Error fetching user cards');
                console.error('Error fetching user cards:', error);
            } finally {
                setLoading(false);
            }
        };

        if (currentUser) {
            fetchUserCards();
        }
    }, [currentUser]);

    const handleFilterChange = newFilters => {
        setFilters(newFilters);
    };

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((o, i) => (o ? o[i] : null), obj);
    };

    const filteredCards = cards.filter(card => {
        return (
            (filters.year ? card.year.toString() === filters.year : true) &&
            (filters.rating ? card.rating >= parseInt(filters.rating) : true) &&
            (filters.team ? card.teamNameID === filters.team : true)
        );
    });

    const availableTeams = Array.from(
        new Set(cards.flatMap(card => (card.teamNameID ? card.teamNameID.split(',').map(teamID => teamID.trim()) : [])))
    ).sort();

    const sortedCards = [...filteredCards].sort((a, b) => {
        const aValue = getNestedValue(a, sortConfig.key);
        const bValue = getNestedValue(b, sortConfig.key);

        if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    return (
        <div className="user-cards-page">
            {error && <p className="error">{error}</p>}
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <h2>
                        My Cards{' '}
                        <button>
                            <Link to="/cards/sell">Sell Cards</Link>
                        </button>
                    </h2>
                    <div className="card-filter">
                        <label>
                            Year:
                            <input
                                type="number"
                                value={filters.year}
                                onChange={e => handleFilterChange({ ...filters, year: e.target.value })}
                            />
                        </label>
                        <label>
                            Rating:
                            <select
                                value={filters.rating}
                                onChange={e => handleFilterChange({ ...filters, rating: e.target.value })}
                            >
                                <option value="">Select rating</option>
                                <option value="96">96+</option>
                                <option value="86">86+</option>
                                <option value="76">76+</option>
                                <option value="66">66+</option>
                                <option value="56">56+</option>
                                <option value="0">all</option>
                            </select>
                        </label>
                        <label>
                            Team:
                            <select
                                value={filters.team}
                                onChange={e => handleFilterChange({ ...filters, team: e.target.value })}
                            >
                                <option value="">Select team</option>
                                {availableTeams.map(key => (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>

                    <CardTable
                        cards={sortedCards}
                        requestSort={requestSort}
                        sortConfig={sortConfig}
                        columns={[
                            { key: 'name', label: 'Name' },
                            { key: 'year', label: 'Year' },
                            { key: 'type', label: 'Type' },
                            { key: 'teamNameID', label: 'Team' },
                            { key: 'positions', label: 'Pos' },
                            { key: 'rating', label: 'Rat' },
                            { key: 'amount', label: 'Quant' },
                        ]}
                    />
                </>
            )}
        </div>
    );
};

export default UserCardsPage;
