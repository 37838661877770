import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';
import { toast } from 'react-toastify';
import CardTable from '../../components/CardTable';
import { fetchUserPacks, openPack } from '../../api/packInventory';

const UserPacksPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const [packs, setPacks] = useState([]);
    const [openedPack, setOpenedPack] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'rating', direction: 'descending' });

    const fetchPacks = useCallback(async () => {
        setError(null);
        try {
            const validPacks = await fetchUserPacks(currentUser.userID);
            setPacks(validPacks);
        } catch (error) {
            setError('Error fetching packs');
            toast.error('Error fetching packs: ' + error.message);
            console.error('Error fetching packs:', error);
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            fetchPacks();
        }
    }, [currentUser, fetchPacks]);

    const handleOpenPack = async ({ seriesID }) => {
        setLoading(true);
        try {
            const data = await openPack({ seriesID });
            const cards = data.cards;
            setOpenedPack({ cards, seriesID });

            // Update the pack count
            setPacks(prevPacks =>
                prevPacks
                    .map(pack => {
                        if (pack.seriesID === seriesID) {
                            const newAmount = pack.amount - 1;
                            return { ...pack, amount: newAmount };
                        }
                        return pack;
                    })
                    .filter(pack => pack.amount > 0)
            );
        } catch (error) {
            toast.error('Error opening pack: ' + error.message);
            console.error('Error opening pack:', error);
        } finally {
            setLoading(false);
        }
    };

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((o, i) => (o ? o[i] : null), obj);
    };

    const sortedCards = openedPack
        ? [...openedPack.cards].sort((a, b) => {
              const aValue = getNestedValue(a, sortConfig.key);
              const bValue = getNestedValue(b, sortConfig.key);

              if (aValue < bValue) {
                  return sortConfig.direction === 'ascending' ? -1 : 1;
              }
              if (aValue > bValue) {
                  return sortConfig.direction === 'ascending' ? 1 : -1;
              }
              return 0;
          })
        : [];

    const getPackImageSrc = key => {
        const imagePath = `/img/card/${key}.png`;

        // Check if the image exists by trying to load it
        const image = new Image();
        image.src = imagePath;
        image.onerror = () => `/img/card/default.png`; // If the image doesn't load, use default.png
        return imagePath;
    };

    if (!currentUser) {
        return <p>Please log in to view your packs.</p>;
    }

    return (
        <div className="user-packs-page">
            <h2>
                My Packs /{' '}
                <button className="button">
                    <Link to="/packs/buy">Buy Packs</Link>
                </button>
            </h2>
            {error && <p className="error">{error}</p>}

            <>
                <div className="packs-grid">
                    {packs.map(pack => {
                        const id = pack.seriesID;
                        const key = pack.Series && pack.Series.seriesKey;
                        const name = pack.Series && pack.Series.name;
                        return (
                            <div key={id} className="pack-item">
                                <img
                                    src={getPackImageSrc(key)}
                                    alt={`${name} pack art`}
                                    className="pack-image"
                                    onError={e => (e.target.src = '/img/card/default.png')}
                                />
                                <div className="pack-details">
                                    <span className="pack-title">
                                        {name} ({pack.amount})
                                    </span>
                                    <button
                                        onClick={() =>
                                            handleOpenPack({
                                                seriesID: pack.seriesID,
                                            })
                                        }
                                        disabled={loading}
                                    >
                                        {loading ? 'Opening Pack...' : 'Open Pack'}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {openedPack && (
                    <div className="opened-pack-details">
                        <h2>Cards Obtained</h2>
                        <CardTable
                            cards={sortedCards}
                            requestSort={requestSort}
                            sortConfig={sortConfig}
                            columns={[
                                { key: 'name', label: 'Name' },
                                { key: 'year', label: 'Year' },
                                { key: 'type', label: 'Type' },
                                { key: 'rating', label: 'Rating' },
                            ]}
                        />
                    </div>
                )}
            </>
        </div>
    );
};

export default UserPacksPage;
