const GLOBALS = {
    TOP_OF_INNING: 'Top',
    BOTTOM_OF_INNING: 'Bottom',
    SO: 'so',
    OO: 'oo',
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    HR: 'hr',
    BB: 'bb',
    K_AFFECT_PCT: 0.5,
    GAME_SPEED: {
        NORMAL: { name: 'normal', value: 1 },
        FAST: { name: 'fast', value: 0.7 },
        FASTER: { name: 'faster', value: 0.4 },
        INSTANT: { name: 'instant', value: 0 },
    },
    FIRST_YEAR: 1871,
    LAST_YEAR: 2023,
    LOW_IPOUTS: 50,
    LOW_IP: 18,
    LOW_AB: 80,
    MID_EVENTS: ['oo', 'h1', 'h2'],
    MANAGED_EVENTS: {
        BB: { name: 'bb', allowed: 'Pitcher' },
        STEAL: { name: 'steal', allowed: 'Batter' },
        EXTRA_BASE: { name: 'extraBase', allowed: 'Batter' },
    },
    LEVEL_EXP: [100, 300, 900, 1500, 3000, 6000, 10000, 15000, 21000, 28000, 36000, 45000, 55000, 66000, 78000],
};

export default GLOBALS;
