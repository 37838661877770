import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const truncate = str => {
    const input = str || '';
    if (input.length > 9) {
        return input.substring(0, 9) + '.';
    }
    return input;
};

const GameScoreboard = ({ scoreboardData, isUserHome, homeTeam, awayTeam }) => {
    let inningScores = (scoreboardData && scoreboardData.inningScores) || [];
    inningScores =
        inningScores.length < 18 ? inningScores.concat(Array(18 - inningScores.length).fill('_')) : inningScores;

    const innings = Math.ceil(inningScores.length / 2);
    const visitorInningScores = [];
    const homeInningScores = [];
    if (inningScores.length % 2 != 0) {
        inningScores.push(0);
    }
    inningScores.forEach((iScore, idx) => {
        if (idx % 2 == 0) {
            visitorInningScores.push(iScore);
        } else {
            homeInningScores.push(iScore);
        }
    });

    //homeHits, visitorHits, homeErrors, visitorErrors
    return (
        <span className="scoreboard">
            <div className="scoreboard-header">
                <span className="team"></span>
                {[...Array(innings)].map((_, idx) => (
                    <span className="inning" key={idx}>
                        {idx + 1}
                    </span>
                ))}
                <span className="inning">R</span>
                <span className="inning">H</span>
                <span className="inning">E</span>
            </div>

            <div className="scoreboard-visitor">
                <span className="team">
                    {isUserHome ? (
                        <>
                            <FontAwesomeIcon icon={faRobot} />
                            {' \u00A0'}
                        </>
                    ) : null}
                    {awayTeam || 'Away'}
                </span>
                <span className="team-small">
                    {isUserHome ? (
                        <>
                            <FontAwesomeIcon icon={faRobot} />
                            {' \u00A0'}
                        </>
                    ) : null}{' '}
                    {truncate(awayTeam) || 'Away'}
                </span>
                {visitorInningScores.map((iScore, idx) => (
                    <span className="inning" key={idx}>
                        {iScore}
                    </span>
                ))}
                <span className="inning">{(scoreboardData && scoreboardData.visitorScore) || 0}</span>
                <span className="inning">{(scoreboardData && scoreboardData.visitorHits) || 0}</span>
                <span className="inning">{(scoreboardData && scoreboardData.visitorErrors) || 0}</span>
            </div>

            <div className="scoreboard-home">
                <span className="team">
                    {!isUserHome ? (
                        <>
                            <FontAwesomeIcon icon={faRobot} />
                            {' \u00A0'}
                        </>
                    ) : null}{' '}
                    {homeTeam || 'Home'}
                </span>
                <span className="team-small">
                    {!isUserHome ? (
                        <>
                            <FontAwesomeIcon icon={faRobot} />
                            {' \u00A0'}
                        </>
                    ) : null}{' '}
                    {truncate(homeTeam) || 'Home'}
                </span>

                {homeInningScores.map((iScore, idx) => (
                    <span className="inning" key={idx}>
                        {iScore}
                    </span>
                ))}
                <span className="inning">{(scoreboardData && scoreboardData.homeScore) || 0}</span>
                <span className="inning">{(scoreboardData && scoreboardData.homeHits) || 0}</span>
                <span className="inning">{(scoreboardData && scoreboardData.homeErrors) || 0}</span>
            </div>
        </span>
    );
};

GameScoreboard.propTypes = {
    scoreboardData: PropTypes.object.isRequired,
    isUserHome: PropTypes.bool.isRequired,
    homeTeam: PropTypes.string,
    awayTeam: PropTypes.string,
};

export default GameScoreboard;
