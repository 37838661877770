import { useEffect } from 'react';

const GA_MEASUREMENT_ID = 'G-PZMXQX6MDM';
const HOTJAR_ID = 5246079;
const HOTJAR_VERSION = 6;

const useAnalytics = () => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            // Helper function to add a script to the document
            const addScript = (src, async = true) => {
                try {
                    const script = document.createElement('script');
                    script.src = src;
                    script.async = async;
                    document.head.appendChild(script);
                } catch (error) {
                    console.error(`Failed to load script: ${src}`, error);
                }
            };

            // Google Analytics Integration
            addScript(`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`);
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', GA_MEASUREMENT_ID);

            // Hotjar Integration
            (function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: HOTJAR_ID, hjsv: HOTJAR_VERSION };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = `${t}${h._hjSettings.hjid}${j}${h._hjSettings.hjsv}`;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
    }, []);
};

export default useAnalytics;
