// src/pages/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../auth/AuthAtoms';

const HomePage = () => {
    const [currentUser] = useAtom(currentUserAtom); // Access current user from Jotai atom
    return (
        <div className="container">
            <h1 className="header">Uranium Baseball</h1>
            <span className="paragraph">{currentUser ? `Welcome back ${currentUser.username}` : ''}</span>
            <span className="paragraph">
                <h3>Things to do first:</h3>
                <ul>
                    <li>
                        <Link to="/packs/buy">Go buy some packs</Link>
                    </li>
                    <li>
                        <Link to="/team/user">Put together your team</Link>
                    </li>
                    <li>
                        <Link to="/campaigns">Go play some of the worst teams</Link>
                    </li>
                </ul>
            </span>
        </div>
    );
};

export default HomePage;
