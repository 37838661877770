import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { cardPropTypes } from '../propTypes/cardPropTypes';
import { getCardRarityClass, getNameLength } from '../utils/cardUtils';
import { translatePitchRoll } from '../utils/gameUtils';
import { fetchAllSeries } from '../api/series';

const Card = ({
    card = {},
    flip = false,
    flipKey = null,
    diceResultH = null,
    diceResultX = null,
    diceResultP = null,
    showNewCards = false,
}) => {
    const [flipped, setFlipped] = useState(false);
    const [seriesData, setSeriesData] = useState([]);

    useEffect(() => {
        if (showNewCards) {
            setFlipped(false);
        } else {
            setFlipped(flip);
        }
    }, [flip, flipKey, showNewCards]);

    useEffect(() => {
        const fetchSeriesData = async () => {
            try {
                const series = await fetchAllSeries();
                setSeriesData(series);
            } catch (error) {
                console.error('Error fetching series data:', error);
            }
        };

        fetchSeriesData();
    }, []);

    const getSeriesKey = () => {
        const series = seriesData.find(s => s.seriesID === card.seriesID);
        return series ? series.seriesKey : null;
    };
    const seriesKey = getSeriesKey();

    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `rotateY(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 },
    });

    const isHighlighted = rowName => {
        return (
            (diceResultH && rowName === `d${diceResultH}`) ||
            (diceResultX && rowName === `xd${diceResultX}`) ||
            (diceResultP && rowName === `pd${diceResultP}`)
        );
    };
    {
        /* TODO: we removed the Series Join from card, and now are getting all the series data from the fetchAllSeries api call above
    we need to look up the seriesKey from the fetchAllSeries data.
    Card will now contain seriesID, and we can look up seriesKey from SeriesID
    */
    }
    const backgroundImage =
        card.CardInventory && card.CardInventory.displayKey
            ? `url(/img/card/${card.CardInventory.displayKey}.png)`
            : card.customImage
            ? `url(${card.customImage})`
            : seriesKey
            ? `url(/img/card/${seriesKey}.png)`
            : `url(/img/card/default.png)`; // Fallback to a default image

    const frontContent = (
        <div
            className="card-front-content"
            style={{
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="card-padding">
                <div className="card-top">
                    <h2>
                        <span className={`square ${getCardRarityClass(card.rating)}`}>{card.rating}</span>
                        <span className={`card-name-length-${getNameLength(card.name)}`}>{card.name}</span>
                    </h2>
                </div>
                <div className="card-bottom nowrap">
                    <p>
                        {card.type === 'Batter' ? <>Speed: {card.speed} </> : null}
                        {card.type === 'Pitcher' ? <>Stamina: {card.stamina} </> : null}
                        <> Pos: {card.positions} </>
                    </p>
                </div>
            </div>
        </div>
    );

    const backContent = (
        <div className="padding2010 width100">
            <h2>
                <span className={`square ${getCardRarityClass(card.rating)}`}>{card.rating}</span>
                <span className={`card-name-length-${getNameLength(card.name)}`}>{card.name}</span>
            </h2>
            {card.type === 'Batter' ? (
                <div className="card-dice">
                    <span className="b-dice">
                        <p className={isHighlighted('d2') ? 'highlight' : ''}>D2: {card.d2}</p>
                        <p className={isHighlighted('d3') ? 'highlight' : ''}>D3: {card.d3}</p>
                        <p className={isHighlighted('d4') ? 'highlight' : ''}>D4: {card.d4}</p>
                        <p className={isHighlighted('d5') ? 'highlight' : ''}>D5: {card.d5}</p>
                        <p className={isHighlighted('d6') ? 'highlight' : ''}>D6: {card.d6}</p>
                        <p className={isHighlighted('d7') ? 'highlight' : ''}>D7: {card.d7}</p>
                        <p className={isHighlighted('d8') ? 'highlight' : ''}>D8: {card.d8}</p>
                        <p className={isHighlighted('d9') ? 'highlight' : ''}>D9: {card.d9}</p>
                        <p className={isHighlighted('d10') ? 'highlight' : ''}>D10: {card.d10}</p>
                        <p className={isHighlighted('d11') ? 'highlight' : ''}>D11: {card.d11}</p>
                        <p className={isHighlighted('d12') ? 'highlight' : ''}>D12: {card.d12}</p>
                    </span>
                    <span className="x-dice">
                        <p className={isHighlighted('xd2') ? 'highlight' : ''}>XD2: {card.xd2}</p>
                        <p className={isHighlighted('xd3') ? 'highlight' : ''}>XD3: {card.xd3}</p>
                        <p className={isHighlighted('xd4') ? 'highlight' : ''}>XD4: {card.xd4}</p>
                        <p className={isHighlighted('xd5') ? 'highlight' : ''}>XD5: {card.xd5}</p>
                        <p className={isHighlighted('xd6') ? 'highlight' : ''}>XD6: {card.xd6}</p>
                        <p className={isHighlighted('xd7') ? 'highlight' : ''}>XD7: {card.xd7}</p>
                        <p className={isHighlighted('xd8') ? 'highlight' : ''}>XD8: {card.xd8}</p>
                        <p className={isHighlighted('xd9') ? 'highlight' : ''}>XD9: {card.xd9}</p>
                        <p className={isHighlighted('xd10') ? 'highlight' : ''}>XD10: {card.xd10}</p>
                        <p className={isHighlighted('xd11') ? 'highlight' : ''}>XD11: {card.xd11}</p>
                        <p className={isHighlighted('xd12') ? 'highlight' : ''}>XD12: {card.xd12}</p>
                    </span>
                </div>
            ) : (
                <div className="card-dice">
                    <span className="p-dice">
                        <p className={isHighlighted('pd2') ? 'highlight' : ''}>
                            PD2: {translatePitchRoll(card.pd2).result}
                        </p>
                        <p className={isHighlighted('pd3') ? 'highlight' : ''}>
                            PD3: {translatePitchRoll(card.pd3).result}
                        </p>
                        <p className={isHighlighted('pd4') ? 'highlight' : ''}>
                            PD4: {translatePitchRoll(card.pd4).result}
                        </p>
                        <p className={isHighlighted('pd5') ? 'highlight' : ''}>
                            PD5: {translatePitchRoll(card.pd5).result}
                        </p>
                        <p className={isHighlighted('pd6') ? 'highlight' : ''}>
                            PD6: {translatePitchRoll(card.pd6).result}
                        </p>
                        <p className={isHighlighted('pd7') ? 'highlight' : ''}>
                            PD7: {translatePitchRoll(card.pd7).result}
                        </p>
                        <p className={isHighlighted('pd8') ? 'highlight' : ''}>
                            PD8: {translatePitchRoll(card.pd8).result}
                        </p>
                        <p className={isHighlighted('pd9') ? 'highlight' : ''}>
                            PD9: {translatePitchRoll(card.pd9).result}
                        </p>
                        <p className={isHighlighted('pd10') ? 'highlight' : ''}>
                            PD10: {translatePitchRoll(card.pd10).result}
                        </p>
                        <p className={isHighlighted('pd11') ? 'highlight' : ''}>
                            PD11: {translatePitchRoll(card.pd11).result}
                        </p>
                        <p className={isHighlighted('pd12') ? 'highlight' : ''}>
                            PD12: {translatePitchRoll(card.pd12).result}
                        </p>
                    </span>
                </div>
            )}
        </div>
    );

    return (
        <div className="card-container" onClick={() => setFlipped(!flipped)}>
            <animated.div className="c card-front" style={{ opacity: opacity.to(o => 1 - o), transform }}>
                {frontContent}
            </animated.div>
            <animated.div
                className="c card-back"
                style={{
                    opacity,
                    transform: transform.to(t => `${t} rotateY(180deg)`),
                }}
            >
                {backContent}
            </animated.div>
        </div>
    );
};

Card.propTypes = cardPropTypes;

export default Card;
