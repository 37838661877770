import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../auth/AuthAtoms';

const AdminRoute = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const isAuthenticated = !!localStorage.getItem('accessToken');

    return isAuthenticated && currentUser?.isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoute;
