import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { createBulkCards } from '../../api/cards';
import GLOBALS from '../../config/globals';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';

const BulkCardsPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const [year, setYear] = useState('');
    const [years, setYears] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const yearsArray = [];
        for (let i = GLOBALS.FIRST_YEAR; i <= GLOBALS.LAST_YEAR; i++) {
            yearsArray.push(i);
        }
        setYears(yearsArray);
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await createBulkCards(year);
            toast.success('Series and cards created successfully!');
        } catch (error) {
            setError('Error creating series and cards');
            toast.error('Error creating series and cards: ' + error.message);
            console.error('Error creating series and cards:', error);
        } finally {
            setLoading(false);
        }
    };

    if (!currentUser?.isAdmin) {
        return <p>Access denied. Only admins can create bulk cards.</p>;
    }

    return (
        <div className="bulk-cards-page">
            <h2>Bulk Card Creation</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <label>
                    Select Year:
                    <select value={year} onChange={e => setYear(e.target.value)} required>
                        <option value="">Select Year</option>
                        {years.map(yr => (
                            <option key={yr} value={yr}>
                                {yr}
                            </option>
                        ))}
                    </select>
                </label>
                <button type="submit" disabled={loading}>
                    {loading ? 'Creating...' : 'Create Series and Cards'}
                </button>
            </form>
        </div>
    );
};

export default BulkCardsPage;
