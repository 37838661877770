import { getDefaultStore } from 'jotai';
import { currentUserAtom, authLoadedAtom } from './AuthAtoms';
import { loginUser, logoutUser, registerUser, fetchUserDetails } from '../api/auth';

const store = getDefaultStore();

export const handleLogin = async (username, password) => {
    try {
        const authResult = await loginUser(username, password);
        const { AccessToken, IdToken, RefreshToken } = authResult;

        // Save tokens to localStorage
        localStorage.setItem('accessToken', AccessToken);
        localStorage.setItem('idToken', IdToken);
        localStorage.setItem('refreshToken', RefreshToken);

        // Fetch user details
        const userDetails = await fetchUserDetails(IdToken);

        // Update state using Jotai atoms
        store.set(currentUserAtom, userDetails.user);
        store.set(authLoadedAtom, true);
    } catch (error) {
        console.error('Login failed:', error.message);
        throw error; // Ensure to propagate the error
    }
};

export const handleLogout = async () => {
    try {
        await logoutUser();

        // Remove tokens from localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        localStorage.removeItem('refreshToken');

        // Clear user from state
        store.set(currentUserAtom, null);
        store.set(authLoadedAtom, false); // Reset authLoaded to false after logout
    } catch (error) {
        console.error('Logout failed:', error.message);
        throw error; // Ensure to propagate the error
    }
};

export const handleRegister = async (username, email, password) => {
    try {
        const data = await registerUser(username, email, password);
        return data;
    } catch (error) {
        console.error('Registration failed:', error.message);
        throw error; // Ensure to propagate the error
    }
};
