import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';
import { toast } from 'react-toastify';
import { fetchAllSeries } from '../../api/series'; // Import the new API function

const AllSeriesPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const [seriesList, setSeriesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadSeries = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await fetchAllSeries();
                // Sort the series by year before setting the state
                const sortedData = data.sort((a, b) => a.year - b.year);
                setSeriesList(sortedData);
                //toast.success('Series fetched successfully!');
            } catch (error) {
                setError('Error fetching series');
                toast.error('Error fetching series: ' + error.message);
                console.error('Error fetching series:', error);
            } finally {
                setLoading(false);
            }
        };

        loadSeries();
    }, []);

    if (!currentUser?.isAdmin) {
        return <p>Access denied. Only admins can view the series.</p>;
    }

    return (
        <div className="all-series-page">
            <h2>All Series</h2>
            {error && <p className="error">{error}</p>}
            {loading ? (
                <p>Loading...</p>
            ) : (
                <ul>
                    {seriesList.map(series => (
                        <li key={series.seriesID}>
                            <Link to={`/admin/series/${series.seriesID}`}>
                                {series.name} ({series.year})
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AllSeriesPage;
