import React, { useState } from 'react';
import { createCampaign } from '../../api/campaigns';
import { toast } from 'react-toastify';

const CreateCampaignPage = () => {
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const payload = { name, desc };
            await createCampaign(payload);
            toast.success('Campaign created successfully!');
            //console.log('Campaign created successfully:', newCampaign);
        } catch (error) {
            setError('Error creating campaign: ' + error.message);
            toast.error('Error creating campaign: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-campaign-page">
            <h2>Create Campaign</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input id="name" type="text" value={name} onChange={e => setName(e.target.value)} required />
                </div>
                <div>
                    <label htmlFor="desc">Description:</label>
                    <textarea id="desc" value={desc} onChange={e => setDesc(e.target.value)} required />
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Creating...' : 'Create Campaign'}
                </button>
            </form>
        </div>
    );
};

export default CreateCampaignPage;
