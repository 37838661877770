import React, { useEffect, useState } from 'react';
import { fetchLeaderboard } from '../../api/cards';
import { toast } from 'react-toastify';
import LeaderboardList from '../../components/LeaderboardList';

const LeaderboardPage = () => {
    const [leaderboard, setLeaderboard] = useState({ batters: [], pitchers: [] });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await fetchLeaderboard();
                setLeaderboard(data);
            } catch (error) {
                toast.error('Error fetching leaderboard: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    if (loading) {
        return <p>Loading...</p>;
    }
    return <LeaderboardList leaderboard={leaderboard} title={'All Time '} />;
};

export default LeaderboardPage;
