export const getCardRarityClass = rating => {
    if (rating <= 55) return 'card-iron';
    if (rating <= 65) return 'card-bronze';
    if (rating <= 75) return 'card-silver';
    if (rating <= 85) return 'card-gold';
    if (rating <= 95) return 'card-titanium';
    return 'card-uranium';
};

export const getNameLength = name => {
    if (!name) return 1; // Handle edge case for empty or null strings

    const wideCharacters = new Set(['W', 'M']); // Add wide characters here
    const isUpperCaseWide = char => char >= 'A' && char <= 'Z' && wideCharacters.has(char);

    // Adjust length for wide and uppercase characters
    let adjustedLength = 0;
    for (const char of name) {
        if (wideCharacters.has(char) || isUpperCaseWide(char)) {
            adjustedLength += 2; // Wide characters count extra
        } else if (char >= 'A' && char <= 'Z') {
            adjustedLength += 1.5; // Uppercase letters count slightly more
        } else {
            adjustedLength += 1; // Default for other characters
        }
    }
    //console.log(name, name.length, adjustedLength);
    // Determine the result based on adjusted length
    if (adjustedLength >= 24) return 5;
    if (adjustedLength >= 20) return 4;
    if (adjustedLength >= 16) return 3;
    if (adjustedLength >= 12) return 2;
    return 1;
};
