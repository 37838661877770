import React, { useEffect, useState } from 'react';
import { fetchEloTeams } from '../../api/userTeams';
import { calcAllOldElo } from '../../api/games';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

const LeaderboardPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const [leaderboard, setLeaderboard] = useState();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchEloTeams();
            data.sort((a, b) => (b.elo || 0) - (a.elo || 0));
            setLeaderboard(data || []);
        };

        fetchData();
    }, []);

    const handleSubmitElo = async () => {
        setSubmitting(true);
        try {
            await calcAllOldElo();
            toast.success('Elo updated successfully!');
        } catch (error) {
            toast.error('Error updating elo: ' + error.message);
        } finally {
            setSubmitting(false);
        }
    };
    return (
        <div className="elo-container">
            <h1 className="header">
                <Link to={`/leaderboard`}>Leaderboard</Link> | Team Rating
            </h1>

            {currentUser && currentUser.isAdmin && (
                <div>
                    Score all Old Elo Games
                    <button onClick={handleSubmitElo} disabled={submitting}>
                        {submitting ? 'Scoring...' : 'Score'}
                    </button>
                </div>
            )}

            <div>
                <h2>Teams</h2>
                {(leaderboard || []).map(team => (
                    <>
                        {team.elo && (
                            <div key={team.userTeamID}>
                                {team.User ? team.User.username : <FontAwesomeIcon icon={faRobot} />} - {team.name} -{' '}
                                {team.elo}
                            </div>
                        )}
                    </>
                ))}
            </div>
        </div>
    );
};

export default LeaderboardPage;
