import React, { useState, useEffect } from 'react';
import { createCampaignContest, fetchAllCampaigns } from '../../api/campaigns';
import { fetchCompTeams } from '../../api/userTeams';
import { toast } from 'react-toastify';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';

const CreateCampaignContestPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const [campaignID, setCampaignID] = useState('');
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [rewardID, setRewardID] = useState('');
    const [result, setResult] = useState('');
    const [userTeamID, setUserTeamID] = useState('');
    const [numOfGames, setNumOfGames] = useState('');
    const [campaigns, setCampaigns] = useState([]);
    const [userTeams, setUserTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [campaignData, userTeamsData] = await Promise.all([fetchAllCampaigns(), fetchCompTeams()]);
                setCampaigns(campaignData);
                setUserTeams(userTeamsData);
            } catch (error) {
                toast.error('Error fetching data: ' + error.message);
            }
        };

        if (currentUser) {
            fetchData();
        }
    }, [currentUser]);

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const payload = { campaignID, name, desc, rewardID, result, userTeamID, numOfGames };
            await createCampaignContest(payload);
            toast.success('Campaign Contest created successfully!');
            //console.log('Campaign Contest created successfully:', newCampaignContest);
        } catch (error) {
            setError('Error creating campaign contest: ' + error.message);
            toast.error('Error creating campaign contest: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-campaign-contest-page">
            <h2>Create Campaign Contest</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="campaignID">Campaign:</label>
                    <select id="campaignID" value={campaignID} onChange={e => setCampaignID(e.target.value)} required>
                        <option value="">Select campaign</option>
                        {campaigns.map(campaign => (
                            <option key={campaign.campaignID} value={campaign.campaignID}>
                                {campaign.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input id="name" type="text" value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="desc">Description:</label>
                    <textarea id="desc" value={desc} onChange={e => setDesc(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="rewardID">Reward ID:</label>
                    <input id="rewardID" type="text" value={rewardID} onChange={e => setRewardID(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="result">Result:</label>
                    <input id="result" type="text" value={result} onChange={e => setResult(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="userTeamID">Comp Team:</label>
                    <select id="userTeamID" value={userTeamID} onChange={e => setUserTeamID(e.target.value)} required>
                        <option value="">Select comp team</option>
                        {userTeams.map(team => (
                            <option key={team.userTeamID} value={team.userTeamID}>
                                {team.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="numOfGames">Number of Games:</label>
                    <input
                        id="numOfGames"
                        type="number"
                        value={numOfGames}
                        onChange={e => setNumOfGames(e.target.value)}
                    />
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Creating...' : 'Create Campaign Contest'}
                </button>
            </form>
        </div>
    );
};

export default CreateCampaignContestPage;
