import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { fetchCompTeams, fetchUserTeams } from '../../api/userTeams';
import { fetchCampaignContestByID } from '../../api/campaigns';
import { createGame } from '../../api/games';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';
import { toast } from 'react-toastify';

const CreateGamePage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const { campaignContestID } = useParams();
    const navigate = useNavigate();
    const [userTeams, setUserTeams] = useState([]);
    const [opponentTeams, setOpponentTeams] = useState([]);
    const [homeUserTeamID, setHomeUserTeamID] = useState('');
    const [visitingUserTeamID, setVisitingUserTeamID] = useState('');
    const [role, setRole] = useState('home');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserTeamsData = async () => {
            try {
                const userTeamsData = await fetchUserTeams();
                setUserTeams(userTeamsData);
            } catch (error) {
                toast.error('Error fetching user teams: ' + error.message);
            }
        };

        const fetchOpponentTeams = async () => {
            try {
                const opponentTeamsData = await fetchCompTeams();
                setOpponentTeams(opponentTeamsData);
            } catch (error) {
                toast.error('Error fetching opponent teams: ' + error.message);
            }
        };

        const fetchContestDetails = async () => {
            if (campaignContestID) {
                try {
                    const campaignContest = await fetchCampaignContestByID(campaignContestID);
                    const randomRole = Math.random() < 0.5 ? 'home' : 'away';
                    setRole(randomRole);
                    if (randomRole === 'home') {
                        setHomeUserTeamID(campaignContest.userTeamID);
                    } else {
                        setVisitingUserTeamID(campaignContest.userTeamID);
                    }
                } catch (error) {
                    toast.error('Error fetching campaign contest: ' + error.message);
                }
            }
        };

        if (currentUser) {
            fetchUserTeamsData();
            fetchOpponentTeams();
            fetchContestDetails();
        }
    }, [currentUser, campaignContestID]);

    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const payload = {
                homeUserTeamID: homeUserTeamID,
                visitingUserTeamID: visitingUserTeamID,
                role,
                campaignContestID: campaignContestID ? campaignContestID : null,
            };

            await createGame(payload);
            toast.success('Game created successfully!');
            //console.log('Game created successfully:', newGame);

            navigate('/games/user', { state: { message: 'Game created successfully!' } });
        } catch (error) {
            setError('Error creating game: ' + error.message);
            toast.error('Error creating game: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-game-page">
            <h2>Create Game</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="userTeam">Your Team:</label>
                    <select
                        id="userTeam"
                        value={role === 'home' ? homeUserTeamID : visitingUserTeamID}
                        onChange={e =>
                            role === 'home' ? setHomeUserTeamID(e.target.value) : setVisitingUserTeamID(e.target.value)
                        }
                        required
                        disabled={!!campaignContestID} // Disable the user team selection if campaign contest ID is provided
                    >
                        <option value="">Select your team</option>
                        {userTeams.map(team => (
                            <option key={team.userTeamID} value={team.userTeamID}>
                                {team.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="opponentTeam">Opponent Team:</label>
                    <select
                        id="opponentTeam"
                        value={role === 'home' ? visitingUserTeamID : homeUserTeamID}
                        onChange={e =>
                            role === 'home' ? setVisitingUserTeamID(e.target.value) : setHomeUserTeamID(e.target.value)
                        }
                        required
                    >
                        <option value="">Select opponent team</option>
                        {opponentTeams.map(team => (
                            <option key={team.userTeamID} value={team.userTeamID}>
                                {team.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Role:</label>
                    <div>
                        <label>
                            <input
                                type="radio"
                                value="home"
                                checked={role === 'home'}
                                onChange={() => setRole('home')}
                            />
                            Home
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="away"
                                checked={role === 'away'}
                                onChange={() => setRole('away')}
                            />
                            Away
                        </label>
                    </div>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Creating...' : 'Create Game'}
                </button>
            </form>
            <div>
                <Link to="/games/user">Games</Link>
            </div>
        </div>
    );
};

export default CreateGamePage;
