import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchUserGames } from '../../api/games';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';
import { toast } from 'react-toastify';

const ViewGamesPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const [hideCompleted, setHideCompleted] = useState(true);

    useEffect(() => {
        const fetchGames = async () => {
            setLoading(true);
            setError(null);
            try {
                const gamesData = await fetchUserGames(currentUser.userID);
                setGames(gamesData);
            } catch (error) {
                setError('Error fetching games: ' + error.message);
                toast.error('Error fetching games: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        if (currentUser) {
            fetchGames();
        }

        if (location.state?.message) {
            toast.success(location.state.message);
        }
    }, [currentUser, location.state]);

    const toggleHideCompleted = () => {
        setHideCompleted(!hideCompleted);
    };

    const filteredGames = hideCompleted ? games.filter(game => !game.isFinal) : games;

    return (
        <div className="view-games-page">
            <h2>My Games</h2>
            <div>
                <Link to="/game/create">Create a New Game</Link>
                <br />
                <br />
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={hideCompleted} onChange={toggleHideCompleted} />
                    Hide Completed Games
                </label>
            </div>
            {error && <p className="error">{error}</p>}
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="view-games-list">
                    <table className="table-striped">
                        <thead>
                            <tr>
                                <th>Home</th>
                                <th>Visiting</th>
                                <th>Date</th>
                                <th>Campaign</th>
                                <th>Final</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredGames.map(game => (
                                <tr key={game.gameID}>
                                    <td>
                                        {game.GameParticipants.find(p => p.role === 'home')?.UserTeam?.name || 'N/A'}
                                    </td>
                                    <td>
                                        {game.GameParticipants.find(p => p.role === 'away')?.UserTeam?.name || 'N/A'}
                                    </td>
                                    <td>{new Date(game.created).toLocaleDateString()}</td>
                                    <td>{game.userCampaignContestID ? 'Yes' : ''}</td>
                                    <td>{game.isFinal ? 'Yes' : 'No'}</td>
                                    <td>
                                        {game.isFinal ? (
                                            <Link to={`/game/${game.gameID}/summary`}>View Summary</Link>
                                        ) : (
                                            <button className="button">
                                                <Link to={`/game/${game.gameID}/play`}>Play</Link>
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default ViewGamesPage;
