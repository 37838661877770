import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchWallet } from '../api/wallet';
import { toast } from 'react-toastify';

const WalletPage = () => {
    const [wallet, setWallet] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWalletData = async () => {
            setLoading(true);
            setError(null);
            try {
                const walletData = await fetchWallet();
                setWallet(walletData);
                //toast.success('Wallet fetched successfully!');
            } catch (error) {
                setError('Error fetching wallet');
                toast.error('Error fetching wallet: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchWalletData();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <div className="wallet-page">
            <h2>Wallet</h2>
            <div>
                <Link to="/wallet/add-money">Add Money</Link>
            </div>
            {wallet ? (
                <div className="wallet-details">
                    <h3>Balance: {wallet.balance}</h3>
                    {wallet.WalletTransactions.length === 0 ? (
                        <p>No transactions found.</p>
                    ) : (
                        <table className="wallet-transactions-table table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Balance</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {wallet.WalletTransactions.map(transaction => (
                                    <tr key={transaction.transactionID}>
                                        <td>{new Date(transaction.created).toLocaleString()}</td>
                                        <td>{transaction.amount}</td>
                                        <td>{transaction.balance}</td>
                                        <td>{transaction.reason}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            ) : (
                <p>Wallet not found.</p>
            )}
        </div>
    );
};

export default WalletPage;
