import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { sortLineup, isPositionAllowed } from '../helpers/gameHelper';
import { subPlayer } from '../api/games';
import { toast } from 'react-toastify';

const GameLineup = ({ participant, latestEvent, isSub = false, onClose }) => {
    const starters = participant.GameLineups.filter(gl => gl.fieldingPosition && !gl.gameEventIDExited);
    const bench = participant.GameLineups.filter(gl => !gl.fieldingPosition && !gl.gameEventIDExited);
    const used = participant.GameLineups.filter(gl => gl.gameEventIDExited);
    const [subInPlayer, setSubInPlayer] = useState(null);
    const [subOutPlayer, setSubOutPlayer] = useState(null);

    const handleSubInClick = player => {
        setSubInPlayer(player);
        if (subOutPlayer && !isPositionAllowed(player.Card.positions, subOutPlayer.fieldingPosition)) {
            setSubOutPlayer(null);
        }
    };

    const handleSubOutClick = player => {
        setSubOutPlayer(player);
        if (subInPlayer && !isPositionAllowed(subInPlayer.Card.positions, player.fieldingPosition)) {
            setSubInPlayer(null);
        }
    };
    const submitSubstitution = async () => {
        //AWESOME IT WORKS
        //close the sub page modal
        const subData = {
            gameID: participant.gameID,
            lineupOrder: subOutPlayer.lineupOrder,
            position: subOutPlayer.fieldingPosition,
            removedGameLineupID: subOutPlayer.gameLineupID,
            addedGameLineupID: subInPlayer.gameLineupID,
        };
        //console.log('subData', subData);
        try {
            await subPlayer(subData);
            toast.success(`Player substituted successfully!`);
            onClose();
        } catch (error) {
            toast.error('Error substituting player: ' + error.message);
            console.error('Error substituting player:', error);
        }
    };

    const renderLineup = (lineup, allowSubIn, allowSubOut, showPlayerPositions) => {
        //this makes my brain hurt
        const canSubIn = subOutPlayer ? isPositionAllowed(lineup.Card.positions, subOutPlayer.fieldingPosition) : true;
        const canSubOut = subInPlayer ? isPositionAllowed(subInPlayer.Card.positions, lineup.fieldingPosition) : true;

        return (
            <div
                key={lineup.gameLineupID}
                className={`${
                    lineup.gameLineupID === latestEvent?.nextBatterLineup?.gameLineupID ||
                    lineup.gameLineupID === latestEvent?.nextPitcherLineup?.gameLineupID
                        ? 'highlight'
                        : ''
                }`}
                style={{
                    opacity: (allowSubIn && !canSubIn) || (allowSubOut && !canSubOut) ? 0.5 : 1,
                    pointerEvents: (allowSubIn && !canSubIn) || (allowSubOut && !canSubOut) ? 'none' : 'auto',
                }}
            >
                <span>
                    {lineup.lineupOrder !== null ? lineup.lineupOrder : ''}: {lineup.Card?.year} {lineup.Card?.name} (
                    {lineup.Card?.rating}){lineup.fieldingPosition ? ` - ${lineup.fieldingPosition} ` : ''}
                    {showPlayerPositions ? ` (${lineup.Card.positions}) ` : ''}
                </span>
                {isSub && allowSubIn && <button onClick={() => handleSubInClick(lineup)}>Sub In</button>}
                {isSub && allowSubOut && <button onClick={() => handleSubOutClick(lineup)}>Sub Out</button>}
            </div>
        );
    };

    return (
        <div className="participant">
            <h4>
                {participant.role} Team: {participant.UserTeam?.name}{' '}
                {!participant.userID && <FontAwesomeIcon icon={faRobot} />}
            </h4>
            <div className="lineup-part">
                <span className="bold">Lineup</span>
                {sortLineup(starters)?.map(lineup => renderLineup(lineup, false, true, false))}
            </div>
            {bench.length > 0 && (
                <div className="lineup-part">
                    <span className="bold">Bench</span>
                    {bench.map(lineup => renderLineup(lineup, true, false, true))}
                    {used.length > 0 && (
                        <div className="lineup-part">
                            <span className="bold">Out of Game</span>
                            {used.map(lineup => renderLineup(lineup, false, false, true))}
                        </div>
                    )}
                </div>
            )}

            {isSub && (
                <div>
                    {subInPlayer || subOutPlayer ? (
                        <>
                            Substitute {subInPlayer?.Card.name || '...'} for {subOutPlayer?.Card.name || '...'}
                        </>
                    ) : (
                        <></>
                    )}
                    <br />
                    <button
                        onClick={() => {
                            setSubInPlayer(null);
                            setSubOutPlayer(null);
                            //console.log('Cancel Substitution');
                        }}
                    >
                        Cancel Substitution
                    </button>
                    <button onClick={submitSubstitution} disabled={!subInPlayer || !subOutPlayer}>
                        Submit Substitution
                    </button>
                </div>
            )}
        </div>
    );
};

GameLineup.propTypes = {
    participant: PropTypes.shape({
        gameID: PropTypes.any,
        gameParticipantID: PropTypes.any,
        role: PropTypes.string.isRequired,
        userID: PropTypes.any,
        UserTeam: PropTypes.shape({
            name: PropTypes.string,
        }),
        GameLineups: PropTypes.arrayOf(
            PropTypes.shape({
                gameLineupID: PropTypes.any,
                fieldingPosition: PropTypes.string,
                lineupOrder: PropTypes.number,
                Card: PropTypes.shape({
                    year: PropTypes.number,
                    name: PropTypes.string,
                    rating: PropTypes.number,
                    positions: PropTypes.string,
                }),
            })
        ).isRequired,
    }).isRequired,
    latestEvent: PropTypes.shape({
        nextBatterLineup: PropTypes.shape({
            gameLineupID: PropTypes.any,
        }),
        nextPitcherLineup: PropTypes.shape({
            gameLineupID: PropTypes.any,
        }),
        gameEventID: PropTypes.any,
    }),
    isSub: PropTypes.bool,
    onClose: PropTypes.func,
};

export default GameLineup;
