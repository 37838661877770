import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { getCardRarityClass } from '../utils/cardUtils';
import { sortTeamLineup } from '../helpers/teamHelpers';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../auth/AuthAtoms';
import PropTypes from 'prop-types';

const TeamList = ({ teams }) => {
    const [currentUser] = useAtom(currentUserAtom);
    const [expandedTeamID, setExpandedTeamID] = useState(null);
    const navigate = useNavigate(); // Use navigate for navigation to the edit page

    const toggleTeam = teamID => {
        setExpandedTeamID(expandedTeamID === teamID ? null : teamID);
    };

    const handleEdit = team => {
        // Navigate to the create/edit page with team data for editing
        navigate('/team/edit', { state: { team } });
    };

    return (
        <div className="team-list-wrapper">
            {teams.length === 0 ? (
                <p>No teams found.</p>
            ) : (
                <div className="team-list">
                    {teams.map(team => (
                        <div key={team.userTeamID} className="team-card">
                            <div className="team-header" onClick={() => toggleTeam(team.userTeamID)}>
                                <span className="team-name">{team.name}</span>
                                <span className="team-score">Score: {team.score}</span>
                            </div>
                            <div className={`team-lineup ${expandedTeamID === team.userTeamID ? 'expanded' : ''}`}>
                                <span>Lineup</span>
                                {sortTeamLineup(team.UserTeamLineups).map(lineup => (
                                    <div key={lineup.lineupID} className="lineup-row">
                                        {lineup.lineupOrder && (
                                            <span className="batting-order bold"> {lineup.lineupOrder} </span>
                                        )}
                                        <span className="position"> {lineup.position} </span>
                                        <Link to={`/cards/${lineup.Card.cardID}`}>
                                            <span className={`square ${getCardRarityClass(lineup.Card.rating)}`}></span>
                                            ({lineup.Card.rating}) {lineup.Card.year} {lineup.Card.name}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            {/* Check if the user is the team owner or isAdmin to show the Edit button */}
                            {(team.userID === currentUser.userID || currentUser.isAdmin) && (
                                <button className="edit-button" onClick={() => handleEdit(team)}>
                                    Edit
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

TeamList.propTypes = {
    teams: PropTypes.arrayOf(
        PropTypes.shape({
            userTeamID: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired,
            score: PropTypes.number.isRequired,
            UserTeamLineups: PropTypes.arrayOf(
                PropTypes.shape({
                    lineupID: PropTypes.any.isRequired,
                    position: PropTypes.string.isRequired,
                    Card: PropTypes.shape({
                        cardID: PropTypes.any.isRequired,
                        rating: PropTypes.number.isRequired,
                        year: PropTypes.number.isRequired,
                        name: PropTypes.string.isRequired,
                    }).isRequired,
                    lineupOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
                })
            ).isRequired,
        })
    ).isRequired,
};

export default TeamList;
