import React, { useEffect, useState } from 'react';
import { fetchAllCampaigns } from '../../api/campaigns';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';

const ViewCampaignsPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCampaigns = async () => {
            setLoading(true);
            setError(null);
            try {
                const campaigns = await fetchAllCampaigns();
                setCampaigns(campaigns);
            } catch (error) {
                setError('Error fetching campaigns');
                toast.error('Error fetching campaigns: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCampaigns();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <div className="view-campaigns-page">
            <h2>View Campaigns</h2>
            {currentUser && currentUser.isAdmin ? (
                <div>
                    <span>
                        <Link to="/campaigns/create">Create Campaign</Link>&nbsp;&nbsp;
                    </span>
                    <span>
                        <Link to="/campaign-contests/create">Create Campaign Contests</Link>
                    </span>
                </div>
            ) : (
                <></>
            )}
            {campaigns.length === 0 ? (
                <p>No campaigns found.</p>
            ) : (
                <div className="campaigns-list">
                    <br />
                    {campaigns.map(campaign => (
                        <div key={campaign.campaignID} className="campaign-item">
                            <Link to={`/campaigns/${campaign.campaignID}`}>
                                <b>{campaign.name}</b>
                            </Link>
                            : <span>{campaign.desc}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ViewCampaignsPage;
