import axios from './axios';

export const updateGameSpeed = async gameSpeed => {
    try {
        const response = await axios.put('/users/setGameSpeed', { gameSpeed });
        return response.data;
    } catch (error) {
        console.error('Error updating game speed:', error);
        throw error;
    }
};

export const updateDiscordUsername = async discordUsername => {
    try {
        const response = await axios.put('/users/setDiscordUsername', { discordUsername });
        return response.data;
    } catch (error) {
        console.error('Error updating discordUsername:', error);
        throw error;
    }
};

export const fetchUsers = async () => {
    try {
        const response = await axios.get('/users/all');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user details:', error);
        return null;
    }
};

export const awardPackToUser = async awardData => {
    try {
        const response = await axios.post('/packInventory/award', awardData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const awardMoneyToUser = async awardData => {
    try {
        const response = await axios.post('/wallet/award', awardData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};
