export const getMonthlyLeaderboardMonths = () => {
    const monthlyStartYear = 2025;
    const monthlyStartMonth = 1;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed

    const months = [];

    let year = currentYear;
    let month = currentMonth;

    while (year > monthlyStartYear || (year === monthlyStartYear && month >= monthlyStartMonth)) {
        months.push({ month, year });

        // Decrement the month, and handle year change if necessary
        month -= 1;
        if (month < 1) {
            month = 12;
            year -= 1;
        }
    }

    return months;
};

export const readableMonthYear = monthYear => {
    const monthYearString = monthYear.toString();
    const year = '20' + monthYearString.slice(0, 2); // Add "20" to the two-digit year
    const month = monthYearString.slice(2, 4); // Extract the month part (last 2 digits)

    const monthName = numberToMonth(Number(month)); // Use the existing numberToMonth function to get the month name

    return `${monthName} ${year}`;
};

export const numberToMonth = monthNumber => {
    const date = new Date(0); // Start with a base date (January 1, 1970)
    date.setMonth(monthNumber - 1); // Set the month (adjusted for zero-based index)
    return date.toLocaleString('en-US', { month: 'short' }); // Get the three-letter month abbreviation
};
