import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../auth/AuthAtoms';
import { updateGameSpeed, updateDiscordUsername } from '../api/user';
import GLOBALS from '../config/globals';
import { getLevelAndNextExp } from '../helpers/userHelpers';
import { toast } from 'react-toastify';

const AccountPage = () => {
    const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
    const [gameSpeed, setGameSpeed] = useState(currentUser?.gameSpeed || GLOBALS.GAME_SPEED.NORMAL.name);
    const [discordUsername, setDiscordUsername] = useState(currentUser?.discordUsername || '');
    const [level, setLevel] = useState(0);
    const [expForNextLevel, setExpForNextLevel] = useState(0);

    useEffect(() => {
        // Initialize state from currentUser
        if (currentUser) {
            setGameSpeed(currentUser.gameSpeed || GLOBALS.GAME_SPEED.NORMAL.name);
            setDiscordUsername(currentUser.discordUsername || '');

            // Calculate level and XP for the next level
            const { level, expForNextLevel } = getLevelAndNextExp(currentUser.exp || 0);
            setLevel(level);
            setExpForNextLevel(expForNextLevel);
        }
    }, [currentUser]);

    const handleGameSpeedChange = async e => {
        const selectedSpeed = e.target.value;
        setGameSpeed(selectedSpeed);

        try {
            await updateGameSpeed(selectedSpeed); // Backend API call
            setCurrentUser({ ...currentUser, gameSpeed: selectedSpeed }); // Update local state
            toast.success('Game speed updated successfully!');
        } catch (err) {
            console.error('Error updating game speed:', err);
            toast.error('Failed to update game speed: ' + err.message);
        }
    };

    const handleDiscordUsernameChange = async e => {
        e.preventDefault();

        try {
            await updateDiscordUsername(discordUsername); // Backend API call
            setCurrentUser({ ...currentUser, discordUsername }); // Update local state
            toast.success('Discord username updated successfully!');
        } catch (err) {
            console.error('Error updating Discord username:', err);
            toast.error('Failed to update Discord username: ' + err.message);
        }
    };

    return (
        <div className="container">
            <h1 className="header">Account</h1>
            <p className="paragraph">
                <strong>Current User Details:</strong>
                <br />
                <span>
                    Experience Points: {currentUser?.exp || 0} / {expForNextLevel}
                </span>
                <br />
                <span>Level: {level}</span>
            </p>
            <p className="paragraph">
                <strong>Settings:</strong>
                <br />
                Game Animation Speed:
                <select value={gameSpeed} onChange={handleGameSpeedChange}>
                    <option value={GLOBALS.GAME_SPEED.NORMAL.name}>Normal</option>
                    <option value={GLOBALS.GAME_SPEED.FAST.name}>Fast</option>
                    <option value={GLOBALS.GAME_SPEED.FASTER.name}>Fastest</option>
                    <option value={GLOBALS.GAME_SPEED.INSTANT.name}>Instant</option>
                </select>
            </p>
            <form onSubmit={handleDiscordUsernameChange} className="paragraph">
                <label>
                    <strong>Discord Username:</strong>
                    <br />
                    <input
                        type="text"
                        value={discordUsername}
                        onChange={e => setDiscordUsername(e.target.value)}
                        placeholder="Enter your Discord username"
                    />
                </label>
                <button type="submit">Update</button>
            </form>
        </div>
    );
};

export default AccountPage;
