import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../auth/AuthAtoms';

const PrivateRoute = () => {
    const [currentUser] = useAtom(currentUserAtom);
    return currentUser ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
