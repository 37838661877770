import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import GLOBALS from '../../config/globals';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';
import { fetchGameStatus, rollGameDice, managedEvent } from '../../api/games';
import { fetchUserCardInventory } from '../../api/cards';
import { translateOuts, translateEvent, translatePitchRoll, isMidEventAdvanceRunners } from '../../utils/gameUtils';
import Card from '../../components/Card';
import GameLineup from '../../components/GameLineup';
import Modal from '../../components/Modal';
import SubPlayerPage from './SubPlayerPage';
import MidEventPage from './MidEventPage';
import StealEventPage from './StealEventPage';
import FlipBoard from '../../components/Flipboard';
import DiceAnimation from '../../components/DiceAnimation';
import GameScoreboard from './GameScoreboard';
import { isUserPitching } from '../../helpers/gameHelper';

const getGameSpeedValue = userGameSpeed => {
    const gameSpeedEntry = Object.values(GLOBALS.GAME_SPEED).find(
        speed => speed.name.toLowerCase() === userGameSpeed?.toLowerCase()
    );
    return gameSpeedEntry ? gameSpeedEntry.value : GLOBALS.GAME_SPEED.NORMAL.value;
};

const PlayGamesPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const gameSpeed = getGameSpeedValue(currentUser.gameSpeed);

    const { gameID } = useParams();
    const [game, setGame] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rolling, setRolling] = useState(false);
    const [flipCards, setFlipCards] = useState(false);
    const [currentBatterCard, setCurrentBatterCard] = useState(null);
    const [currentPitcherCard, setCurrentPitcherCard] = useState(null);
    const [userCardInventory, setUserCardInventory] = useState(null); // New state for user's card inventory
    const [animateDice, setAnimateDice] = useState(false);
    const [showNewCards, setShowNewCards] = useState(false);
    const [showPitchResult, setShowPitchResult] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [showRunners, setShowRunners] = useState(false);
    const [oldGameStatus, setOldGameStatus] = useState(null);
    const [userIsPitching, setUserIsPitching] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMidEventModalOpen, setIsMidEventModalOpen] = useState(false);
    const [isStealModalOpen, setIsStealModalOpen] = useState(false);
    const animationTimeMs = 3000 * gameSpeed;

    const [isManagerOpen, setIsManagerOpen] = useState(false);
    const toggleManager = () => setIsManagerOpen(!isManagerOpen);

    const [isParticipantsOpen, setIsParticipantsOpen] = useState(false);
    const toggleParticipants = () => setIsParticipantsOpen(!isParticipantsOpen);

    useEffect(() => {
        const getGameStatus = async () => {
            setLoading(true);
            setError(null);
            try {
                const gameData = await fetchGameStatus(gameID);
                setGame(gameData);
                setOldGameStatus(gameData);
                setCurrentBatterCard(gameData.GameEvents?.[0]?.nextBatterLineup?.Card);
                setCurrentPitcherCard(gameData.GameEvents?.[0]?.nextPitcherLineup?.Card);
            } catch (error) {
                setError('Error fetching game status: ' + error.message);
                toast.error('Error fetching game status: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        getGameStatus();
    }, [gameID]);

    // Helper function to get user card IDs from the game
    const getUserCardIDs = (game, userID) => {
        const userParticipant = game.GameParticipants?.find(participant => participant.userID === userID);
        return userParticipant?.GameLineups?.map(lineup => lineup.cardID) || [];
    };

    useEffect(() => {
        const getUserCardInventory = async () => {
            if (!game) return;

            const userCardIDs = getUserCardIDs(game, currentUser.userID); // Get card IDs for the user's cards in the game

            if (userCardIDs.length === 0) return; // If no card IDs found, do nothing

            try {
                const inventoryData = await fetchUserCardInventory(userCardIDs); // Fetch inventory for specific cards
                setUserCardInventory(inventoryData);
            } catch (error) {
                setError('Error fetching user card inventory: ' + error.message);
                toast.error('Error fetching user card inventory: ' + error.message);
            }
        };

        getUserCardInventory();
    }, [currentUser.userID, game]);

    // Attach cardInventory to the user's cards in the game
    useEffect(() => {
        if (userCardInventory && game) {
            const attachCardInventory = card => {
                const foundInventory = userCardInventory.find(inventory => inventory.cardID === card.cardID);
                if (foundInventory) {
                    card.CardInventory = foundInventory; // Attach card inventory to the card
                }
            };

            if (currentBatterCard) {
                attachCardInventory(currentBatterCard);
            }

            if (currentPitcherCard) {
                attachCardInventory(currentPitcherCard);
            }
        }
    }, [userCardInventory, game, currentBatterCard, currentPitcherCard]);

    useEffect(() => {
        if (game && currentUser) {
            setUserIsPitching(isUserPitching(game, currentUser));
            setIsMidEventModalOpen(!!isMidEventAdvanceRunners(game.GameEvents?.[0], userIsPitching));
        }
    }, [game, currentUser]);

    const executeGameEvent = async (eventFunction, eventArgs) => {
        setRolling(true);
        setAnimateDice(false);
        setFlipCards(false);
        setShowNewCards(false);
        setShowPitchResult(false);
        setShowResult(false);
        setShowRunners(false);
        setOldGameStatus(game);

        try {
            const updatedGame = await eventFunction(eventArgs);
            setGame(updatedGame);
            setAnimateDice(true);
            setTimeout(() => setFlipCards(true), 0);
            setTimeout(() => setShowPitchResult(true), 0);
            setTimeout(() => setShowResult(true), animationTimeMs / 3);
            setTimeout(() => setShowRunners(true), animationTimeMs / 2);
            setTimeout(() => {
                setCurrentBatterCard(updatedGame.GameEvents?.[0]?.nextBatterLineup?.Card);
                setCurrentPitcherCard(updatedGame.GameEvents?.[0]?.nextPitcherLineup?.Card);
                setShowNewCards(true);
                setRolling(false);
            }, animationTimeMs);
        } catch (error) {
            toast.error(`Error: ${error.message}`);
            setRolling(false);
        }
    };

    const managedEventWalk = async () => {
        await executeGameEvent(managedEvent, { gameID, event: 'bb' });
    };

    const handleRollDice = async () => {
        await executeGameEvent(rollGameDice, gameID);
    };

    const subMenu = async () => {
        setIsModalOpen(true); // Open the modal
    };

    const handleModalClose = async () => {
        setIsModalOpen(false);
        await setGameData();
    };

    const handleStealModalClose = async () => {
        setIsStealModalOpen(false);
        await setGameData();
    };

    const handleExtraBaseModalClose = async () => {
        setIsMidEventModalOpen(false);
        await setGameData();
    };

    const setGameData = async () => {
        const gameData = await fetchGameStatus(gameID);
        setGame(gameData);
        setOldGameStatus(gameData);
        setCurrentBatterCard(gameData.GameEvents?.[0]?.nextBatterLineup?.Card);
        setCurrentPitcherCard(gameData.GameEvents?.[0]?.nextPitcherLineup?.Card);
    };
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    const homeParticipant = game.GameParticipants?.find(participant => participant.role === 'home');
    const awayParticipant = game.GameParticipants?.find(participant => participant.role === 'away');
    const userParticipant = game.GameParticipants?.find(participant => participant.userID);
    const isUserHome = userParticipant === homeParticipant;
    const latestEvent = game.GameEvents?.[0] || [];
    const oldLatestEvent = oldGameStatus?.GameEvents?.[0] || latestEvent;

    const buttonLabel = userIsPitching ? 'Hurl' : 'Swing';

    const strikeThroughH = latestEvent.resultType !== latestEvent.origResultType;
    const strikeThroughP = latestEvent.resultType === latestEvent.origResultType;

    const runnersOnBase = !!(
        latestEvent.firstBaseRunnerLineupID ||
        latestEvent.secondBaseRunnerLineupID ||
        latestEvent.thirdBaseRunnerLineupID
    );
    const showSteal = !userIsPitching && runnersOnBase;

    return (
        <div className="play-games-page">
            {game && (
                <div>
                    <GameScoreboard
                        scoreboardData={game.scoreboardData}
                        isUserHome={isUserHome}
                        homeTeam={homeParticipant.UserTeam?.name}
                        awayTeam={awayParticipant.UserTeam?.name}
                    />
                    <div className="lineups">
                        {' '}
                        {/* lineup should always stay on the same row, just scale down in size AWALYS EVEN ON MOBILE*/}
                        <div className="lineup">
                            <h3 className="nowrap">
                                Next Batter {userIsPitching ? <FontAwesomeIcon icon={faRobot} /> : ''}
                            </h3>
                            {currentBatterCard && (
                                <Card
                                    card={currentBatterCard}
                                    flip={flipCards}
                                    flipKey={`${latestEvent?.diceResultH}-${latestEvent?.diceResultX}`}
                                    diceResultH={showNewCards ? null : latestEvent?.diceResultH}
                                    diceResultX={showNewCards ? null : latestEvent?.diceResultX}
                                    showNewCards={showNewCards}
                                />
                            )}
                        </div>
                        <div className="lineup">
                            <h3 className="nowrap">
                                Next Pitcher {userIsPitching ? '' : <FontAwesomeIcon icon={faRobot} />}
                            </h3>
                            {currentPitcherCard && (
                                <Card
                                    card={currentPitcherCard}
                                    flip={flipCards}
                                    flipKey={latestEvent?.diceResultP}
                                    diceResultP={showNewCards ? null : latestEvent?.diceResultP}
                                    showNewCards={showNewCards}
                                />
                            )}
                        </div>
                    </div>
                    <div className="game-info">
                        {game.isFinal ? (
                            <span className="final-summary">
                                Final: <Link to={`/game/${game.gameID}/summary`}>Game Summary</Link>
                            </span>
                        ) : (
                            <>
                                <button className="button" onClick={toggleManager}>
                                    Manager
                                </button>
                                <button className="roll-dice-button" onClick={handleRollDice} disabled={rolling}>
                                    {rolling ? 'Rolling...' : buttonLabel}
                                </button>
                            </>
                        )}
                    </div>
                    {isManagerOpen && (
                        <div className="accordion-content">
                            <h3>Manager Options</h3>
                            {userIsPitching ? (
                                <button onClick={managedEventWalk}>Intentional Walk</button>
                            ) : (
                                <button className="inactive">Intentional Walk</button>
                            )}
                            <button className="inactive">Throw at Batter</button>
                            <button onClick={subMenu}>Substitute</button>

                            {showSteal ? (
                                <button onClick={() => setIsStealModalOpen(true)}>Steal</button>
                            ) : (
                                <button className="inactive">Steal</button>
                            )}

                            <button className="inactive">Argue with the Ump</button>
                            <button className="inactive">Get Ejected</button>
                        </div>
                    )}
                    {isModalOpen && (
                        <Modal onClose={handleModalClose}>
                            <SubPlayerPage
                                participant={userParticipant}
                                latestEvent={game.GameEvents?.[0]}
                                onClose={handleModalClose}
                            />
                        </Modal>
                    )}
                    {isMidEventModalOpen && (
                        <Modal onClose={handleExtraBaseModalClose}>
                            <MidEventPage
                                participant={userParticipant}
                                latestEvent={game.GameEvents?.[0]}
                                onClose={handleExtraBaseModalClose}
                            />
                        </Modal>
                    )}
                    {isStealModalOpen && (
                        <Modal onClose={handleStealModalClose}>
                            <StealEventPage
                                participant={userParticipant}
                                latestEvent={game.GameEvents?.[0]}
                                onClose={handleStealModalClose}
                            />
                        </Modal>
                    )}
                    <div className="events-container lineup">
                        {/* event card and dice-container should always stay on the same row, EVEN ON MOBILE*/}
                        <div className="event-card">
                            <div className="highlight current-status nowrap">
                                <b>
                                    {translateOuts(showRunners ? latestEvent.ipOuts : oldLatestEvent?.ipOuts).complete}
                                </b>
                            </div>

                            <div className="current-event">
                                <div className="current-event">
                                    <FlipBoard
                                        result={translateEvent(latestEvent.resultType).event}
                                        smallResult={translateEvent(latestEvent.resultType).event}
                                        flip={showResult}
                                        flipDuration={300}
                                        height={'50px'}
                                    />

                                    <br />
                                    <span>{latestEvent.resultDescription}</span>
                                </div>
                            </div>

                            <span className="baserunners">
                                <div className={latestEvent.firstBaseRunnerLineup?.Card?.name ? 'highlight' : ''}>
                                    1B:{' '}
                                    <b>
                                        {showRunners
                                            ? latestEvent.firstBaseRunnerLineup?.Card?.name
                                            : oldLatestEvent?.firstBaseRunnerLineup?.Card?.name}
                                    </b>
                                </div>
                                <div className={latestEvent.secondBaseRunnerLineup?.Card?.name ? 'highlight' : ''}>
                                    2B:{' '}
                                    <b>
                                        {showRunners
                                            ? latestEvent.secondBaseRunnerLineup?.Card?.name
                                            : oldLatestEvent?.secondBaseRunnerLineup?.Card?.name}
                                    </b>
                                </div>
                                <div className={latestEvent.thirdBaseRunnerLineup?.Card?.name ? 'highlight' : ''}>
                                    3B:{' '}
                                    <b>
                                        {showRunners
                                            ? latestEvent.thirdBaseRunnerLineup?.Card?.name
                                            : oldLatestEvent?.thirdBaseRunnerLineup?.Card?.name}
                                    </b>
                                </div>
                            </span>
                        </div>

                        <div className="dice-container">
                            {/* dice container should ALWAYS be side by side with events container, even on mobile*/}
                            {animateDice && (
                                <div className="dice-div">
                                    <span className="dice">
                                        Batting Dice:
                                        <div className="dice-flex">
                                            <DiceAnimation
                                                diceResultH={latestEvent.diceResultH}
                                                diceResultX={latestEvent.diceResultX}
                                                gameSpeed={gameSpeed}
                                            />
                                            <FlipBoard
                                                result={translateEvent(latestEvent.origResultType).event}
                                                smallResult={translateEvent(latestEvent.resultType).smallEvent}
                                                strikeThrough={strikeThroughH}
                                                flip={animateDice}
                                                flipDuration={300}
                                                height={'40px'}
                                            />
                                        </div>
                                    </span>
                                </div>
                            )}
                            {showPitchResult && (
                                <div className="dice-div">
                                    <span className="dice">
                                        Pitch Dice:
                                        {animateDice && (
                                            <div className="dice-flex">
                                                <DiceAnimation
                                                    diceResultP={latestEvent.diceResultP}
                                                    gameSpeed={gameSpeed}
                                                />
                                                <FlipBoard
                                                    result={translatePitchRoll(latestEvent.pitchResultType).result}
                                                    smallResult={
                                                        translatePitchRoll(latestEvent.pitchResultType).smallResult
                                                    }
                                                    strikeThrough={strikeThroughP}
                                                    flip={animateDice}
                                                    flipDuration={300}
                                                    height={'40px'}
                                                />
                                            </div>
                                        )}
                                        <br />
                                    </span>
                                    <br />
                                </div>
                            )}
                        </div>
                    </div>
                    <h3 onClick={toggleParticipants} className="accordion-header">
                        Participants
                    </h3>

                    {isParticipantsOpen && (
                        <div className="participants-container">
                            {game.GameParticipants?.map(participant => (
                                <GameLineup
                                    key={participant.gameParticipantID}
                                    participant={participant}
                                    latestEvent={latestEvent}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PlayGamesPage;
