import React, { useEffect, useState, useRef } from 'react';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../../auth/AuthAtoms';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
    fetchPublicCampaignByID,
    fetchUserCampaignContests,
    updateCampaign,
    createUserCampaignContest,
} from '../../api/campaigns';
import { toast } from 'react-toastify';

const CampaignDetailPage = () => {
    const [currentUser] = useAtom(currentUserAtom);
    const { campaignID } = useParams();
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState(null);
    const [userCampaignContests, setUserCampaignContests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        desc: '',
    });

    const hasFetchedData = useRef(false); // Add a ref to track if data has been fetched

    useEffect(() => {
        const fetchCampaignData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await fetchPublicCampaignByID(campaignID);
                setCampaign(data);
                setFormData({
                    name: data.name,
                    desc: data.desc,
                });
                //toast.success('Campaign fetched successfully!');
            } catch (error) {
                setError('Error fetching campaign');
                toast.error('Error fetching campaign: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        if (!hasFetchedData.current) {
            fetchCampaignData();
            hasFetchedData.current = true; // Set the ref to true after fetching
        }

        if (currentUser) {
            const fetchUserContests = async () => {
                try {
                    const contests = await fetchUserCampaignContests(campaignID);
                    setUserCampaignContests(contests.CampaignContests);
                } catch (error) {
                    console.error('Error fetching user campaign contests:', error.message);
                }
            };

            fetchUserContests();
        }
    }, [campaignID, currentUser]);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        setLoading(true);
        setError(null);
        try {
            const updatedCampaign = await updateCampaign(campaignID, formData);
            setCampaign(updatedCampaign);
            setEditMode(false);
            toast.success('Campaign updated successfully!');
        } catch (error) {
            setError('Error updating campaign');
            toast.error('Error updating campaign: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <span>Loading...</span>;
    }

    if (error) {
        return <span className="error">{error}</span>;
    }

    if (!campaign) {
        return <span>No campaign found.</span>;
    }

    return (
        <div className="campaign-detail-page">
            <h2>Campaign Details</h2>
            {editMode ? (
                <div>
                    <div>
                        <label>Name:</label>
                        <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label>Description:</label>
                        <textarea name="desc" value={formData.desc} onChange={handleInputChange} />
                    </div>
                    <button onClick={handleSave} disabled={loading}>
                        {loading ? 'Saving...' : 'Save'}
                    </button>
                </div>
            ) : (
                <div>
                    <div>
                        <span>
                            <b>Name:</b> {campaign.name}
                        </span>
                    </div>
                    <div>
                        <span>
                            <b>Description:</b> {campaign.desc}
                        </span>
                    </div>
                    {currentUser && currentUser.isAdmin && <button onClick={() => setEditMode(true)}>Edit</button>}
                </div>
            )}
            <div className="campaign-contests">
                <h3>Campaign Contests</h3>
                {campaign.CampaignContests?.length > 0 ? (
                    <div>
                        {campaign.CampaignContests.map(contest => {
                            const userCampaignContest = userCampaignContests.find(
                                ucc => ucc.campaignContestID === contest.campaignContestID
                            );

                            const handleStart = async () => {
                                try {
                                    const newUserCampaignContest = await createUserCampaignContest(
                                        contest.campaignContestID,
                                        currentUser.userID
                                    );
                                    navigate(`/campaign-contests/${newUserCampaignContest.userCampaignContestID}/play`);
                                } catch (error) {
                                    console.error('Error starting campaign contest:', error.message);
                                }
                            };

                            return (
                                <div key={contest.campaignContestID} className="campaign-contest">
                                    <Link to={`/campaign-contests/${contest.campaignContestID}`}>
                                        <b>{contest.UserTeam.name}</b>
                                    </Link>
                                    :{' '}
                                    <span>
                                        {contest.numOfGames} game(s), {contest.desc}{' '}
                                    </span>
                                    <span>
                                        {userCampaignContest && userCampaignContest.UserCampaignContests.length ? (
                                            userCampaignContest.result ? (
                                                <>Result: {userCampaignContest.result}</>
                                            ) : (
                                                <button>
                                                    <Link
                                                        to={`/campaign-contests/${userCampaignContest.UserCampaignContests[0].userCampaignContestID}/play`}
                                                    >
                                                        Continue
                                                    </Link>
                                                </button>
                                            )
                                        ) : (
                                            currentUser && <button onClick={handleStart}>Start</button>
                                        )}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <span>No contests available.</span>
                )}
            </div>
            <div>Campaign History</div>
            <div>
                <Link to="/campaigns">Back to Campaigns</Link>
            </div>
        </div>
    );
};

export default CampaignDetailPage;
