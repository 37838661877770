import GLOBALS from '../config/globals';

export const getLevelAndNextExp = exp => {
    if (typeof exp !== 'number' || isNaN(exp)) {
        return { level: 1, expForNextLevel: 0 }; // Default to level 1 if `exp` is not a valid number
    }

    const { LEVEL_EXP } = GLOBALS;

    let low = 0;
    let high = LEVEL_EXP.length - 1;

    // Binary search to find the level
    while (low <= high) {
        const mid = Math.floor((low + high) / 2);

        if (exp < LEVEL_EXP[mid]) {
            high = mid - 1; // Search the left half
        } else {
            low = mid + 1; // Search the right half
        }
    }

    // At the end of the loop, `low` points to the first boundary greater than `exp`
    const level = low + 1; // Levels are 1-based

    // Calculate experience needed for the next level
    const expForNextLevel = LEVEL_EXP[level - 1] ? LEVEL_EXP[level - 1] : '???';

    return { level, expForNextLevel };
};
