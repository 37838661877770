import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from './routes';
import './css/nerd.css';
import Layout from './components/Layout';
import AuthInitializer from './auth/AuthInitializer';
import CombinedProvider from './contexts/CombinedProvider';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAnalytics from './hooks/useAnalytics';

export default function App() {
    useAnalytics();

    return (
        <React.StrictMode>
            <Router>
                <AuthInitializer />
                <CombinedProvider>
                    <Layout>
                        <Routes>
                            {routes.map(({ path, component: Component, private: isPrivate, admin: isAdmin }) =>
                                isPrivate ? (
                                    <Route key={path} path={path} element={<PrivateRoute />}>
                                        <Route path={path} element={<Component />} />
                                    </Route>
                                ) : isAdmin ? (
                                    <Route key={path} path={path} element={<AdminRoute />}>
                                        <Route path={path} element={<Component />} />
                                    </Route>
                                ) : (
                                    <Route key={path} path={path} element={<Component />} />
                                )
                            )}
                        </Routes>
                        <ToastContainer
                            autoClose={1000} // Global autoClose duration (1.5 seconds)
                            hideProgressBar={false} // Optionally hide the progress bar
                            position="top-right" // Set position of the toast
                            newestOnTop={true} // Optionally show the newest toast on top
                        />
                    </Layout>
                </CombinedProvider>
            </Router>
        </React.StrictMode>
    );
}
