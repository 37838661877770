//SubPlayerPage.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import GLOBALS from '../../config/globals';
import { submitMidEventChoice } from '../../api/games';
import { translateOuts, translateEvent } from '../../utils/gameUtils';

const MidEventPage = ({ latestEvent, onClose }) => {
    const [submitting, setSubmitting] = useState(false);
    const handleMidEventChoice = async runnersAdvancing => {
        try {
            setSubmitting(true);
            await submitMidEventChoice(runnersAdvancing, latestEvent.gameID);
            onClose();
        } catch (err) {
            toast.error(`Error: ${err.message}`);
        } finally {
            setSubmitting(false);
        }
    };
    const firstRunner = latestEvent.firstBaseRunnerLineup
        ? `${latestEvent.firstBaseRunnerLineup.Card.year} ${latestEvent.firstBaseRunnerLineup.Card.name} - Speed ${latestEvent.firstBaseRunnerLineup.Card.speed}`
        : null;
    const secondRunner = latestEvent.secondBaseRunnerLineup
        ? `${latestEvent.secondBaseRunnerLineup.Card.year} ${latestEvent.secondBaseRunnerLineup.Card.name} - Speed ${latestEvent.secondBaseRunnerLineup.Card.speed}`
        : null;
    const thirdRunner = latestEvent.thirdBaseRunnerLineup
        ? `${latestEvent.thirdBaseRunnerLineup.Card.year} ${latestEvent.thirdBaseRunnerLineup.Card.name} - Speed ${latestEvent.thirdBaseRunnerLineup.Card.speed}`
        : null;

    //console.log(firstRunner, secondRunner, thirdRunner);
    const advancingRunners = [];
    if (firstRunner && latestEvent.resultType != GLOBALS.H1) {
        advancingRunners.push(1);
    }
    if (secondRunner && latestEvent.resultType != GLOBALS.H2) {
        advancingRunners.push(2);
    }
    if (thirdRunner && latestEvent.resultType != GLOBALS.H3) {
        advancingRunners.push(3);
    }

    const advancingRunnerOptions = [];
    const advancingRunnerText = [];
    if (advancingRunners.includes(1)) {
        if (advancingRunners.includes(2)) {
            if (advancingRunners.includes(3)) {
                advancingRunnerOptions.push('1,2,3');
                advancingRunnerText.push('Try to advance runners from 1st, 2nd and 3rd');
            } else {
                advancingRunnerOptions.push('1,2');
                advancingRunnerText.push('Try to advance runners from 1st and 2nd');
            }
        } else {
            advancingRunnerOptions.push('1');
            advancingRunnerText.push('Try to advance runner from 1st');
        }
    }

    if (advancingRunners.includes(2)) {
        if (advancingRunners.includes(3)) {
            advancingRunnerOptions.push('2,3');
            advancingRunnerText.push('Try to advance runners from 2nd and 3rd');
        } else {
            advancingRunnerOptions.push('2');
            advancingRunnerText.push('Try to advance runner from 2nd');
        }
    }

    if (advancingRunners.includes(3)) {
        advancingRunnerOptions.push('3');
        advancingRunnerText.push('Try to advance runner from 3rd');
    }

    return (
        <div>
            <h2>Mid Event Page</h2>
            <div>
                <b>
                    {translateOuts(latestEvent.ipOuts).complete} {translateEvent(latestEvent.resultType).event}!
                </b>
                <br />
                {latestEvent.resultDescription}
            </div>
            <div>
                <br />
                1st: {firstRunner}
            </div>
            <div>2nd: {secondRunner}</div>
            <div>3rd: {thirdRunner}</div>
            {advancingRunnerOptions.length ? (
                <>
                    <br />
                    {advancingRunnerOptions.map((opt, idx) => {
                        return (
                            <>
                                <button
                                    key={idx}
                                    onClick={() => handleMidEventChoice(opt)}
                                    className={submitting ? 'inactive' : ''}
                                >
                                    {advancingRunnerText[idx]}
                                </button>
                                <br />
                            </>
                        );
                    })}
                    <button onClick={() => handleMidEventChoice(null)} className={submitting ? 'inactive' : ''}>
                        Advance Nobody
                    </button>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

MidEventPage.propTypes = {
    latestEvent: PropTypes.shape({
        gameID: PropTypes.number.isRequired,
        resultDescription: PropTypes.string.isRequired,
        resultType: PropTypes.string.isRequired,
        ipOuts: PropTypes.number.isRequired,
        firstBaseRunnerLineup: PropTypes.shape({
            Card: PropTypes.shape({
                year: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                speed: PropTypes.number.isRequired,
            }),
        }),
        secondBaseRunnerLineup: PropTypes.shape({
            Card: PropTypes.shape({
                year: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                speed: PropTypes.number.isRequired,
            }),
        }),
        thirdBaseRunnerLineup: PropTypes.shape({
            Card: PropTypes.shape({
                year: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                speed: PropTypes.number.isRequired,
            }),
        }),
    }).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default MidEventPage;
