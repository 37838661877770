export const TEAMS = {
    ANA: 'California', //LAA
    ARI: 'Arizona', //ARI
    ATL: 'Atlanta', //ATL
    ALT: 'Altoona Mountain City', //none
    BAL: 'Baltimore', //BAL
    BL1: 'Baltimore Canaries', //none
    BL2: 'Baltimore', //BAL
    BL3: 'Baltimore', //BAL
    BLA: 'Baltimore', //BAL
    BLF: 'Baltimore Terrapins',
    BLN: 'Baltimore', //BAL
    BLU: 'Baltimore  Monumentals', //BAL
    BL4: 'Baltimore  Marylands', //BAL
    BOS: 'Boston', //BOS
    BS1: 'Boston', //ATL
    BS2: 'Boston', //none
    BR1: 'Brooklyn Eckfords', //LAN
    BR2: 'Brooklyn Atlantics', //LAN
    BR3: 'Brooklyn', //LAN
    BR4: 'Brooklyn Gladiators', //LAN
    BRF: 'Brooklyn Tip-Tops', //LAN
    BRP: "Brooklyn Ward's Wonders", //LAN
    BRO: 'Brooklyn Grooms', //LAN
    BUF: 'Buffalo Buffeds', //none
    BFN: 'Buffalo Bisons', //none
    BFP: 'Buffalo Bisons', //none
    BSP: 'Boston ', //boston reds NONE
    BSN: 'Boston Nationals', //ATL
    CAL: 'California', //LAA
    CH1: 'Chicago', //1871 Chicago White Stockings cubs
    CH2: 'Chicago', //1875 Chicago White Stockings CUBS
    CHA: 'Chicago American', //whitesox CH2
    CHF: 'Chicago Chi-Feds', //cubs
    CHP: 'Chicago', // 1890 Chicago White Stockings CUBS
    CHN: 'Chicago National', //cubs CH1
    CHU: 'Chicago/Pittsburgh', // none
    CIN: 'Cincinnati', //CIN
    CN1: 'Cincinnati Stars', //CIN
    CN2: 'Cincinnati', //CIN
    CN3: "Cincinnati Kelly's Killers", //CIN
    CLE: 'Cleveland', //CLE
    CL1: 'Cleveland Forest Citys', // none
    CL2: 'Cleveland Blues', // none
    CL3: 'Cleveland Blues', // none
    CL4: 'Cleveland Spiders', //CLE
    CL5: 'Columbus Buckeyes', // none
    CL6: 'Columbus Solons', // none
    CLP: 'Cleveland Infants', // none
    COL: 'Colorado', //COL
    DET: 'Detroit', //DET
    DTN: 'Detroit Wolverines', // none
    ELI: 'Elizabeth Resolutes', // none
    FLO: 'Florida', //MIA
    FW1: 'Fort Wayne Kekiongas', // none
    HAR: 'Hartford Dark Blues', // none
    HOU: 'Houston', //HOU
    HR1: 'Hartford Dark Blues', // none
    IN1: 'Indianapolis Blues', // none
    IN2: 'Indianapolis Hoosiers', // none
    IN3: 'Indianapolis Hoosiers', // none
    IND: 'Indianapolis Hoosiers', // none
    KCA: 'Kansas City', //KCA
    KCN: 'Kansas City Cowboys', // none
    KC1: 'Kansas City', //OAK
    KC2: 'Kansas City Cowboys', // none
    KCF: 'Kansas City Packers', // none
    KCU: 'Kansas City Cowboys', // none
    KEO: 'Keokuk Westerns', // none
    LAN: 'Los Angeles National', //dodgers LAN
    LAA: 'Los Angeles American', //angels LAA
    LS1: 'Louisville Grays', // none
    LS2: 'Louisville Colonels', // none
    LS3: 'Louisville Colonels', // none
    MIA: 'Miami', //MIA
    MIL: 'Milwaukee', //MIL
    MLA: 'Milwaukee', //BAL
    ML1: 'Milwaukee', //ATL
    ML2: 'Milwaukee Grays', // none
    ML3: 'Milwaukee', // none
    ML4: 'Milwaukee', //MIL
    MLU: 'Milwaukee', //BAL
    MID: 'Middletown Mansfields', // none
    MIN: 'Minnesota', //MIN
    MON: 'Montreal', //WAS
    NH1: 'New Haven Elm Citys', // none
    NEW: 'Newark Pepper', // none
    NYA: 'New York', //yankees NYA
    NYN: 'New York', // mets NYN
    NY1: 'New York', //SFN
    NY2: 'New York Mutuals', // none
    NY3: 'New York Mutuals', // none
    NY4: 'New York Metropolitans', // none
    NYP: 'New York', //SFN
    OAK: 'Oakland', //OAK
    PHU: 'Philadelphia Keystones', // none
    PHA: 'Philadelphia', //OAK
    PH1: 'Philadelphia', // none
    PH2: 'Philadelphia Whites', // none
    PH3: 'Philadelphia', // none
    PH4: 'Philadelphia', // none
    PHI: 'Philadelphia', //phillies PHN
    PHN: 'Philadelphia', // none
    PHP: 'Philadelphia', //OAK
    PIT: 'Pittsburgh', //PIT
    PT1: 'Allegheny City', // none
    PTF: 'Pittsburgh Rebels', // none
    PTP: 'Pittsburgh Burghers', // none
    PRO: 'Providence Grays', // none
    RC1: 'Rockford Forest Citys', // none
    RC2: 'Rochester Broncos', // none
    RIC: 'Richmond Virginians', // none
    SDN: 'San Diego', //SDN
    SFN: 'San Francisco', //SFN
    SEA: 'Seattle', //SEA
    SE1: 'Seattle Pilots', //MIL
    SLA: 'St. Louis Browns', //BAL
    SLF: 'St. Louis Terriers', // none
    SL1: 'St. Louis', // none
    SL2: 'St. Louis Brown Stockings', //STL
    SL3: 'St. Louis Brown Stockings', //STL
    SL4: 'St. Louis Browns', //BAL
    SL5: 'St. Louis Maroons', // none
    SLN: 'St. Louis', //SLN
    SPU: 'St. Paul White Caps', // none
    SR1: 'Syracuse Stars', // none
    SR2: 'Syracuse Stars', // none
    TEX: 'Texas', //TEX
    TBA: 'Tampa Bay', //TBA
    TL1: 'Toledo Blue Stockings', // none
    TL2: 'Toledo Maumees', // none
    TOR: 'Toronto', //TOR
    TRO: 'Troy Haymakers', // none
    WAS: 'Washington', //WAS
    WIL: 'Wilmington Quicksteps', // none
    WOR: 'Worcester Ruby Legs', //ATL
    WS1: 'Washington', //MIN
    WS2: 'Washington Senators', //MIN
    WS3: 'Washington Olympics', // none
    WS4: 'Washington', // none
    WS5: 'Washington Blue Legs', // none
    WS6: 'Washington', // none
    WS7: 'Washington', // none
    WS8: 'Washington', // none
    WS9: 'Washington Statesmen', // none
};

export const teamNameTranslator = teams => {
    const teamCodes = teams.split(',').map(team => team.trim());
    const translatedTeams = teamCodes.map(code => TEAMS[code] || code);
    const uniqueTeams = [...new Set(translatedTeams)];

    return uniqueTeams.join(', ');
};

export const sortTeamLineup = lineup => {
    const lineupOrder = lineup
        .filter(player => player.position !== 'Bench' && player.position !== 'SP' && player.position !== 'RP')
        .sort((a, b) => a.lineupOrder - b.lineupOrder);

    const benchSpots = lineup.filter(player => player.position === 'Bench');
    const sp = lineup.filter(player => player.position === 'SP');
    const rp = lineup.filter(player => player.position === 'RP');

    return [...lineupOrder, ...benchSpots, ...sp, ...rp];
};

const lanTeams = ['BR1', 'BR2', 'BR3', 'BR4', 'BRF', 'BRP', 'BRO', 'LAN'];
const sdnTeams = ['SDN'];
const colTeams = ['COL'];
const ariTeams = ['ARI'];
const sfnTeams = ['SFN', 'NY1', 'NYP'];

const cinTeams = ['CIN', 'CN1', 'CN2', 'CN3'];
const stlTeams = ['STL', 'SL2', 'SL3'];
const chnTeams = ['CHN', 'CH1', 'CH2', 'CHF', 'CHP'];
const pitTeams = ['PIT'];
const milTeams = ['MIL', 'ML4', 'SE1'];

const atlTeams = ['ATL', 'BS1', 'BSN', 'ML1', 'WOR'];
const nynTeams = ['NYN'];
const phiTeams = ['PHI'];
const miaTeams = ['MIA', 'FLO'];
const wasTeams = ['WAS', 'MON'];

const seaTeams = ['SEA'];
const houTeams = ['HOU'];
const oakTeams = ['OAK', 'KC1', 'PHA', 'PHP'];
const laaTeams = ['LAA', 'ANA', 'CAL'];
const texTeams = ['TEX'];

const minTeams = ['WS2', 'MIN', 'WS1'];
const cleTeams = ['CLE', 'CL4'];
const chaTeams = ['CHA'];
const kcaTeams = ['KCA'];
const detTeams = ['DET'];

const balTeams = ['BAL', 'BL2', 'BL3', 'BLA', 'BLN', 'BLU', 'BL4', 'SLA', 'MLU', 'MLA', 'SL4'];
const nyaTeams = ['NYA'];
const bosTeams = ['BOS'];
const tbrTeams = ['TBR'];
const torTeams = ['TOR'];

const noneTeams = [
    'ALT',
    'BL1',
    'BL2',
    'BL3',
    'BLA',
    'BLN',
    'BLU',
    'BL4',
    'BS2',
    'BR1',
    'BR2',
    'BR3',
    'BR4',
    'BRF',
    'BRP',
    'BUF',
    'BFN',
    'BFP',
    'BSP',
    'BSN',
    'CL1',
    'CL2',
    'CL3',
    'CL5',
    'CL6',
    'CLP',
    'DTN',
    'ELI',
    'FW1',
    'HAR',
    'HR1',
    'IN1',
    'IN2',
    'IN3',
    'IND',
    'KCN',
    'KC1',
    'KC2',
    'KCF',
    'KCU',
    'KEO',
    'LS1',
    'LS2',
    'LS3',
    'ML2',
    'ML3',
    'MLU',
    'MID',
    'NH1',
    'NEW',
    'NY2',
    'NY3',
    'NY4',
    'PH1',
    'PH2',
    'PH3',
    'PH4',
    'PHN',
    'PHP',
    'PT1',
    'PTF',
    'PTP',
    'PRO',
    'RC1',
    'RC2',
    'RIC',
    'SPU',
    'SR1',
    'SR2',
    'TL1',
    'TL2',
    'TRO',
    'WIL',
    'WOR',
    'WS3',
    'WS4',
    'WS5',
    'WS6',
    'WS7',
    'WS8',
    'WS9',
];

export const allTeamNames = {
    LAN: lanTeams,
    SDN: sdnTeams,
    COL: colTeams,
    ARI: ariTeams,
    SFN: sfnTeams,
    STL: stlTeams,
    CHN: chnTeams,
    PIT: pitTeams,
    CIN: cinTeams,
    MIL: milTeams,
    ATL: atlTeams,
    NYN: nynTeams,
    PHI: phiTeams,
    MIA: miaTeams,
    WAS: wasTeams,
    SEA: seaTeams,
    HOU: houTeams,
    OAK: oakTeams,
    LAA: laaTeams,
    TEX: texTeams,
    CLE: cleTeams,
    CHA: chaTeams,
    KCA: kcaTeams,
    DET: detTeams,
    MIN: minTeams,
    BAL: balTeams,
    NYA: nyaTeams,
    BOS: bosTeams,
    TBR: tbrTeams,
    TOR: torTeams,
    NONE: noneTeams,
};
